import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Image,
  Text,
  Link,
  Flex,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTMLReactParser from "html-react-parser";
import { timeDifference, formatDate } from "utils";
import standardImage from "../../assets/images/standard_image.png";

export default function ModalGroupsCardDecision({
  isOpen,
  onClose,
  currentDecision,
  isAdmin
}) {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalBody>
          <Box className="main-box-modal-decision-card-host">
            <Image
              className="image-modal-decision-card-host"
              src={
                isError
                  ? standardImage
                  : currentDecision["image-url"] || standardImage
              }
              alt={t("decision")}
              onError={() => setIsError(true)}
            />
            <Text className="title-modal-decision-card-host">
              {currentDecision["title"]}
            </Text>
            <Link
              className="main-link-modal-decision-card-host"
              href={
                currentDecision["current-mode"] === "Unpublished"
                  ? currentDecision?.links?.preview
                  : currentDecision?.links?.visit
              }
              target="_blank"
            >
              {currentDecision["current-mode"] === "Unpublished"
                ? currentDecision?.links?.preview
                : currentDecision?.links?.visit}
            </Link>
            <Flex direction="row" gap={2}>
              {currentDecision?.attributes["current-mode"] === "open" && (
                <Link href={currentDecision?.links?.visit}>
                  <Button className="special-link-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-moon"
                      className="icon-button-decision-card-host"
                    />
                    70% completed
                  </Button>
                </Link>
              )}

              {currentDecision?.attributes["current-mode"]
                ?.toLowerCase()
                .includes("finished") && (
                <Link href={currentDecision?.links?.results}>
                  <Button className="special-link-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-check"
                      className="icon-button-decision-card-host"
                    />
                    {t("btn.view_results")}
                  </Button>
                </Link>
              )}

              {currentDecision?.attributes["current-mode"]
                ?.toLowerCase()
                .includes("unpublished") && (
                <Link href={currentDecision?.links?.results}>
                  <Button className="special-link-button-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-regular fa-moon"
                      className="icon-button-decision-card-host"
                    />
                    {t("btn.unpublished")}
                  </Button>
                </Link>
              )}
            </Flex>
          </Box>
          <Flex direction="column">
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t("decision.details.opened")}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision["open-at"] ? (
                  <>
                    {formatDate(currentDecision["open-at"])} (
                    {timeDifference(currentDecision["open-at"])} )
                  </>
                ) : (
                  <>{t("decision.details.to_be_determined")}</>
                )}
              </Text>
            </Flex>
            <Flex className="date-flex-modal-decision-card-host">
              <Text className="date-tag-modal-decision-card-host">
                {t("decision.details.closes")}
              </Text>
              <Text ml={0} className="date-text-modal-decision-card-host">
                {currentDecision["close-at"] ? (
                  <>
                    {formatDate(currentDecision["close-at"])} (
                    {timeDifference(currentDecision["close-at"])} )
                  </>
                ) : (
                  <>{t("decision.details.to_be_determined")}</>
                )}
              </Text>
            </Flex>

            {/* About Field with Lorem Ipsum */}
            <Flex className="about-flex-modal-decision-card-host">
              <Text className="about-text-modal-decision-card-host">
                {t("decision.details.about")}
              </Text>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Text>
            </Flex>
          </Flex>
          {isAdmin && <Flex
            className="flex-buttons-modal-modal-decision-card-host"
            gap={2}
            direction="row"
          >
            {currentDecision?.links?.visit && (
              <Tooltip label={t("tooltip.visit")}>
                <Link
                  href={currentDecision?.links?.visit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-eye"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.edit && (
              <Tooltip label={t("tooltip.edit")}>
                <Link
                  href={currentDecision?.links?.edit}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-pen"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.analysis && (
              <Tooltip label={t("tooltip.analysis")}>
                <Link
                  href={currentDecision?.links?.analysis}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-chart-simple"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
            {currentDecision?.links?.comments && (
              <Tooltip label={t("tooltip.comments")}>
                <Link
                  href={currentDecision?.links?.comments}
                  target="_blank"
                  width="100%"
                >
                  <Button className="icon-bottom-button-modal-decision-card-host">
                    <FontAwesomeIcon
                      icon="fa-solid fa-comment"
                      className="icon-decision-card-host"
                    />
                  </Button>
                </Link>
              </Tooltip>
            )}
          </Flex>}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => onClose()}
            className="button-details-modal-decision-card-host"
            rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-up" />}
            justifyContent="space-between"
          >
            {t("btn.hide_details")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
