import Client from './client';

const api = () => {
    return {
        getDataOfElectionsWhereUserIsInvitedToHost: () => {
            return Client.get('member/decisions/assigned');
        },
        getDataOfElectionsWhereUserIsInvitedToParticipate: () => {
            return Client.get('member/decisions/invited');
        },
        getDataOfElectionsWhereUserIsParticipating: () => {
            return Client.get('member/decisions/participating');
        },
        getDataOfElectionsWhereUserIsHost: () => {
            return Client.get('member/decisions/hosting');
        },
        getDecisionsTemplates: () => {
            return Client.get('member/groups/1/templates');
        },
        createDecisions: (data) => {
            return Client.post('member/decisions/copy.json', data);
        }
    };
};

export default api();