import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Heading,
  VStack,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations"
import LoadingScreen from "../../components/LoadingScreen";
import notify from "common/utils/notify";
import "./styles.css";
import { changeLanguage, i18n } from "../../translations/i18nextConfig/i18nextConfig";

const TranslationHandler = () => {
  const { t } = useMarkdownTranslations();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/translations.csv`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "translations.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file:", error);
      notify("error", t('errors.file.download'));
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("file", file);
  
    setLoading(true);
  
    try {
      await axios.post(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/upload-translations`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
  
      await changeLanguage(i18n.language);

      notify("success", t('file.uploaded.successfully'));
      fileInputRef.current.value = null;
  
    } catch (error) {
      fileInputRef.current.value = null;
      notify("error", t('errors.file.upload'));
    } finally {
      fileInputRef.current.value = null;
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingScreen loading={loading} />
      <Box p={8} h="90vh">
        <VStack height="100%" spacing={4} p={4} borderRadius={4} align="stretch" background="var(--CLEAR-BACKGROUND)">
          <Heading as="h2" size="lg" textAlign="center" mb={4}>
            {t('translation.management')}
          </Heading>
          <HStack spacing={4} justify="center">
            <Button
              colorScheme="blue"
              onClick={handleDownload}
              className="translation-handler-button"
            >
              {t('btn.translation.download')}
            </Button>
            <Input
              type="file"
              ref={fileInputRef}
              display="none"
              onChange={handleUpload}
              className="translation-handler-input"
            />
            <Button
              colorScheme="green"
              onClick={() => fileInputRef.current.click()}
              className="translation-handler-button"
            >
              {t('btn.translation.upload')}
            </Button>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

export default TranslationHandler;