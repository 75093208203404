import Client from 'api/client';

const baseUrl = 'member/';
const urls = {
  login: baseUrl + 'login_password.json',
  signup: baseUrl + 'join_password.json',
  nonce: baseUrl + 'web3/nonce.json',
  join: baseUrl + 'web3/join.json',
  loginWithMetamask: baseUrl + 'web3/login.json',
  add: baseUrl + 'web3/add.json',
  requestPasswordReset: baseUrl + 'request_password_reset.json',
  resetPassword: baseUrl + 'password_reset.json',
  logout: baseUrl + 'sign_out.json'
};

const api = () => {
  return {
    login: data => {
      return Client.post(urls.login, data);
    },
    signup: data => {
      return Client.post(urls.signup, data);
    },    
    nonce: data => {
      return Client.post(urls.nonce, data);
    },
    join: data => {
      return Client.post(urls.join, data);
    },
    loginWithMetamask: data => {
      return Client.post(urls.loginWithMetamask, data);
    },
    add: data => {
      return Client.post(urls.add, data);
    },
    requestPasswordReset: data => {
      return Client.post(urls.requestPasswordReset, data);
    },
    resetPassword: data => {
      return Client.post(urls.resetPassword, data);
    },
    logout: data => {
      return Client.get(urls.logout, data);
    },
  };
};

export default api();