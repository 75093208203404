import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Select as SelectWithSearch } from "chakra-react-select";
import moment from "moment-timezone";
import { getNames, getCode } from "country-list";
import groupsApi from "../../../api/groups";
import GroupCreatedModal from "../../GroupCreatedModal"; // Import the CreateGroupModal component
import { useCurrentGroupsContext } from "features/groups/contexts/groups-provider";

const getTimezoneObject = (timezone) => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60;
  return {
    value: timezone,
    label: `(GMT${
      offsetHours >= 0 ? `+${offsetHours}` : offsetHours
    }) ${timezone}`,
  };
};

const getTimezonesInFormat = () => {
  const timezones = moment.tz.names();
  return timezones
    .map((timezone) => {
      const currentTime = moment.tz(timezone);
      const offsetHours = currentTime.utcOffset() / 60;
      return {
        timezone,
        offsetHours,
      };
    })
    .sort((a, b) => a.offsetHours - b.offsetHours)
    .map(({ timezone, offsetHours }) => ({
      value: timezone,
      label: `(GMT${
        offsetHours >= 0 ? `+${offsetHours}` : offsetHours
      }) ${timezone}`,
    }));
};

const ModalCreateGroup = ({ isOpen, onClose }) => {
  const { fetchGroups } = useCurrentGroupsContext();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [url, setUrl] = useState("");
  const [info, setInfo] = useState("");
  const [language, setLanguage] = useState(null);
  const [timeZone, setTimeZone] = useState(getTimezoneObject("UTC"));
  const [openModal, setOpenModal] = useState(false);
  const [editGroupLink, setEditGroupLink] = useState("");

  const languages = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
  ];

  const timezonesToDisplay = getTimezonesInFormat();

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(getTimezoneObject(userTimezone));
  }, []);

  const handleCreate = () => {
    const groupData = {
      title,
      slug,
      url,
      info,
      language: language?.value,
      time_zone: timeZone.value,
    };

    groupsApi
      .createGroup(groupData)
      .then((response) => {
        setEditGroupLink(response.data.links.edit); // Set the edit link from API response

        // Call fetchGroups after the group has been successfully created
        return fetchGroups();
      })
      .then(() => {
        setOpenModal(true); // Open the CreateGroupModal
        onClose(); // Close the current modal
      })
      .catch((error) => {
        console.error("Error creating group:", error);
      });
  };

  return (
    <>
      {/*<GroupCreatedModal
        editGroupLink={editGroupLink}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />*/}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "md", md: "xl", lg: "3xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <Text className="modal-header">Create New Group</Text>
          <Text className="modal-header-secondary" ml={4}>
            Groups allow you to host decisions and invite people to join
          </Text>
          <ModalBody p={4}>
            <Flex gap={4}>
              <FormControl mb={4} flex="0.30">
                <Text className="input-form-label">Group Name</Text>

                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="basic-input"
                />
              </FormControl>
              <FormControl mb={4} flex="0.70">
                <Text className="input-form-label">
                  Identifier (your group will be at
                  https://site.com/groups/:identifer){" "}
                </Text>
                <Input
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  className="basic-input"
                />
              </FormControl>
            </Flex>

            <FormControl mb={4}>
              <Text className="input-form-label">More Info URL</Text>
              <Input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="basic-input"
              />
            </FormControl>
            <Flex>
              <Button
                className="secondary-button"
                mr={3}
                onClick={onClose}
                flex="1"
              >
                Cancel
              </Button>
              <Button onClick={handleCreate} className="main-button" flex="1">
                Create Group
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCreateGroup;
