import React, { useState, useEffect } from "react";
import { Text, Card, CardBody, Heading, Flex, Grid } from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import HubspotModal from "components/HubspotModal";
import "./styles.css";

export default function CreateDecisionHeader() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { t } = useMarkdownTranslations();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  return (
    <Card className="card-create-decision-header" variant="outline">
      <Grid
        templateColumns={windowWidth > 1024 ? "82% 18%" : "100%"}
        className="hubspot-grid-decision-header"
        gap={2}
      >
        <CardBody className="card-body-create-decision-header">
          <Heading
            className="heading-create-decision-header"
            role="heading"
            aria-level="1"
            size="md"
          >
            {t("decision.create")}
          </Heading>
          <Text className="text-create-decision-header">
            {t("decision.introduction_to_create")}
          </Text>
        </CardBody>
        <Flex className="flex-hubspot-decision-header">
          <HubspotModal />
        </Flex>
      </Grid>
    </Card>
  );
}