import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Input,
  Tooltip,
  InputGroup,
  Select,
  Stack,
  Text,
  Link,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import LeftArrow from "images/leftArrow";
import RightArrow from "images/rightArrow";
import { useCurrentDecisionsContext } from "features/decisions/contexts/decisions-provider";
import "./styles.css";
import { useParams, useNavigate } from "react-router-dom";
import ModalDecisionCardHost from "components/ModalDecisionCardHost";
import ModalInviteParticipants from "components/ModalInviteParticipants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dataOfTable} from "./testingData";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

const TableOfCurrentDecision = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: invitateOpen,
    onOpen: invitateOnOpen,
    onClose: invitateOnClose,
  } = useDisclosure();
  const [currentDecision, setCurrentDecision] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dataFilteredAndSorted, setDataFilteredAndSorted] =
    useState(dataOfTable);
  const [dataToDisplay, setDataToDisplay] = useState(dataOfTable);
  const { tableDecisionsHostingData, dataOfDecisionsAlreadyCalled } =
    useCurrentDecisionsContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [roleFilter, setRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [flexWidth, setFlexWidth] = useState(0);
  const flexRef = useRef(null);
  const { tMarkdown, t } = useMarkdownTranslations();

  const listOfSorts = [
    { value: "", label: t("participant.sort_by") },
    { value: "name", label: t("participant.name") },
    { value: "email", label: t("participant.email")  },
    { value: "status", label: t("participant.status") },
    { value: "roles", label: t("participant.role.plural") },
  ];
  
  const roles = [
    { value: "", label: t("participant.all.roles") },
    { value: "Admin", label: t("participant.role.admin") },
    { value: "User", label: t("participant.role.user") },
    { value: "Manager", label: t("participant.role.manager") },
  ];
  
  const statuses = [
    { value: "", label: t("participant.all.status") },
    { value: "Active", label: t("participant.status.active") },
    { value: "Pending", label: t("participant.status.pending") },
    { value: "Inactive", label: t("participant.status.inactive") },
  ];  

  const getLowerNumber = (numberA, numberB) => {
    return numberA < numberB ? numberA : numberB;
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (dataFilteredAndSorted.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const paginationButtons = () => {
      const buttonList = [];
      for (let i = 0; i < pagesNumber; i++) {
        buttonList.push(
          <Button
            className="button-change-page-decision-copy-list"
            color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
            bgColor={
              pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
            }
            onClick={() => setPageIndex(i)}
            key={i}
          >
            {i + 1}
          </Button>
        );
      }
      return buttonList;
    };

    return <>{paginationButtons()}</>;
  };

  const truncateString = (str) => {
    return str.length > 9 ? `${str.substring(0, 9)}...` : str;
  };

  useEffect(() => {
    const getDataOfCurrentDecision = async () => {
      try {
        if (id) {
          const dataOfTable = tableDecisionsHostingData.dataOfTable;
          const matchingDecision = dataOfTable.find(
            (item) => item["decision-id"] == id
          );

          if (matchingDecision) {
            setCurrentDecision(matchingDecision);
          } else {
            navigate("/hosted-decisions");
          }
        }
      } catch (error) {
        navigate("/hosted-decisions");
      }
    };

    if (dataOfDecisionsAlreadyCalled) getDataOfCurrentDecision();
  }, [id, dataOfDecisionsAlreadyCalled]);

  useEffect(() => {
    // Filter by search, role, and status
    const filteredData = dataOfTable.filter((row) => {
      const matchesSearch =
        row.name.toLowerCase().includes(search.toLowerCase()) ||
        row.email.toLowerCase().includes(search.toLowerCase());
      const matchesRole = roleFilter ? row.roles === roleFilter : true;
      const matchesStatus = statusFilter ? row.status === statusFilter : true;

      return matchesSearch && matchesRole && matchesStatus;
    });

    // Sorting logic
    const sortedData = filteredData.sort((a, b) => {
      if (sortBy === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortBy === "email") {
        return a.email.localeCompare(b.email);
      } else if (sortBy === "status") {
        return a.status.localeCompare(b.status);
      } else if (sortBy === "roles") {
        return a.roles.localeCompare(b.roles);
      }
      return 0;
    });

    // Set the filtered and sorted data
    setDataFilteredAndSorted(sortedData);
  }, [search, sortBy, roleFilter, statusFilter, dataOfTable]);

  useEffect(() => {
    const positionOfElementToShow = pageIndex * pageSize;
    const positionOfLastElementToShow = getLowerNumber(
      dataFilteredAndSorted.length,
      (pageIndex + 1) * pageSize
    );

    const newDataToDisplay = dataFilteredAndSorted.slice(
      positionOfElementToShow,
      positionOfLastElementToShow
    );

    setDataToDisplay(newDataToDisplay);
  }, [dataFilteredAndSorted, pageIndex]);

  useEffect(() => {
    const updateFlexWidth = () => {
      if (flexRef.current) {
        // Calculate the element's clientWidth
        const value = flexRef.current.clientWidth;
        setFlexWidth(value);
      }
    };

    // Call it on component mount
    updateFlexWidth();

    // Add an event listener for window resize
    window.addEventListener("resize", updateFlexWidth);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateFlexWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ModalDecisionCardHost
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
      />
      <ModalInviteParticipants
        isOpen={invitateOpen}
        onClose={invitateOnClose}
        currentDecision={currentDecision}
      />
      {currentDecision ? (
        <Box className="main-box-table-of-current-decision">
          <Box className="secondary-box-table-of-current-decision">
            <Flex
              ref={flexRef}
              justifyContent={{ base: "center", md: "space-between" }}
              py={4}
              direction={{ base: "column", md: "row" }}
              borderBottom="2px solid var(--INPUT-BACKGROUND)"
              alignItems="flex-start"
              background="var(--CLEAR-BACKGROUND)"
              p={{ base: "8px", md: 0 }}
              borderRadius={{ base: "18px", md: "0px" }}
              mb={{ base: "32px", md: "0px" }}
            >
              <Flex
                direction="column"
                m={0}
                mb={{ base: 8, md: 2 }}
                p={{ base: "8px", md: 0 }}
              >
                <Stack
                  role="main"
                  aria-label={t("accessibility.decision.table.header")}
                  textAlign="left"
                >
                  <Text className="decision-name-table-of-current-decision">
                    {currentDecision?.title}
                  </Text>
                  <Link className="decision-link-table-of-current-decision">
                    {currentDecision?.links?.visit}
                  </Link>
                </Stack>
              </Flex>

              <Button
                className="button-table-of-current-decision"
                onClick={() => onOpen()}
                width={{ base: "100%", md: "auto" }}
              >
                {t("btn.details")}
              </Button>
            </Flex>
            <Box
              background="var(--CLEAR-BACKGROUND)"
              borderTopRightRadius={{ base: "18px", md: "0px" }}
              borderTopLeftRadius={{ base: "18px", md: "0px" }}
              p={{ base: "16px", md: 0 }}
            >
              <Flex
                gap={4}
                mt={4}
                mb={6}
                direction={["column", "column", "column", "column", "row"]}
              >
                <Box flexGrow={1}>
                  <InputGroup>
                    <Input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder={t("input.placeholder.search")}
                      h={12}
                    />
                  </InputGroup>
                </Box>

                <Flex gap={4} direction={["row"]} wrap="wrap" flex="1">
                  <Box flex="1">
                    <Select
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      h={12}
                    >
                      {listOfSorts.map((currentSort, i) => (
                        <option key={i} value={currentSort.value}>
                          {currentSort.label}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box flex="1">
                    <Select
                      value={roleFilter}
                      onChange={(e) => setRoleFilter(e.target.value)}
                      h={12}
                    >
                      {roles.map((role, i) => (
                        <option key={i} value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box flex="1">
                    <Select
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                      h={12}
                    >
                      {statuses.map((status, i) => (
                        <option key={i} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Flex>

                <Button
                  className="button-table-of-current-decision"
                  onClick={() => invitateOnOpen()}
                  mt={[4, 4, 0]}
                  width={["100%", "100%", "auto"]}
                >
                  {t("decision.participants.invite")}
                </Button>
              </Flex>
              <Box overflow="auto" maxWidth={`${flexWidth}px`}>
                <Table
                  variant="simple"
                  size="md"
                  borderWidth="1px"
                  borderColor="gray.100"
                  width="100%"
                  boxShadow="md"
                >
                  <Thead className="thead-table-of-current-decision">
                    <Tr>
                      <Th className="th-table-of-current-decision">
                        {t("participant.name")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.email")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.web3_address")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.status")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.role.singular")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.infl")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.invite_sent")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.last_sign_in")}
                      </Th>
                      <Th className="th-table-of-current-decision">
                        {t("participant.pencent_done")}
                      </Th>
                      <Th className="th-table-of-current-decision"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataToDisplay.map((row) => (
                      <Tr key={row.id}>
                        <Td className="td-table-of-current-decision">
                          {row.name}
                        </Td>
                        <Td className="td-table-of-current-decision">
                          <Tooltip label={row.email} placement="top">
                            <Flex align="center" justify="space-between">
                              {truncateString(row.email)}
                              <IconButton
                                aria-label={t("accessibility.btn.copy_email")}
                                icon={<CopyIcon />}
                                size="sm"
                                variant="ghost"
                                onClick={() =>
                                  navigator.clipboard.writeText(row.email)
                                }
                              />
                            </Flex>
                          </Tooltip>
                        </Td>
                        <Td className="td-table-of-current-decision">
                          <Tooltip label={row.web3Address} placement="top">
                            <Flex align="center" justify="space-between">
                              {truncateString(row.web3Address)}
                              <IconButton
                                aria-label={t("accessibility.btn.copy_web3_address")}
                                icon={<CopyIcon />}
                                size="sm"
                                variant="ghost"
                                onClick={() =>
                                  navigator.clipboard.writeText(row.web3Address)
                                }
                              />
                            </Flex>
                          </Tooltip>
                        </Td>
                        <Td className="td-table-of-current-decision">
                          {row.status}
                        </Td>
                        <Td className="td-table-of-current-decision">
                          {row.roles}
                        </Td>
                        <Td className="td-table-of-current-decision">
                          {row.infl}
                        </Td>
                        <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                          {row.inviteSent}
                        </Td>
                        <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                          {row.lastSignIn}
                        </Td>
                        <Td className="td-table-of-current-decision">N/A</Td>
                        <Td>
                          <HStack spacing={2}>
                            <Button
                              size="sm"
                              className="action-button-table-of-current-decision"
                            >
                              {t("authentication.password.reset")}
                            </Button>
                            <Button
                              size="sm"
                              className="action-button-table-of-current-decision"
                            >
                              {t("btn.resent_invite")}
                            </Button>
                            <Button
                              size="sm"
                              className="action-button-table-of-current-decision"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-pencil-alt" />
                            </Button>
                            <Button
                              size="sm"
                              className="action-button-table-of-current-decision"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-trash" />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Box>
            {windowWidth > 768 ? (
              <Flex
                className="outer-flex-participate-page"
                role="region"
                aria-label={t("accessibility.pagination.arrows")}
                borderBottomLeftRadius={{ base: "18px", md: "0px" }}
                borderBottomRightRadius={{ base: "18px", md: "0px" }}
              >
                <Flex className="column-flex-participate-page">
                  <Text className="showing-text-participate-page">
                    {t("pagination.show_pagination", {
                      from:
                        dataFilteredAndSorted.length > 0
                          ? pageIndex * pageSize + 1
                          : 0,
                      to: getLowerNumber(
                        dataFilteredAndSorted.length,
                        (pageIndex + 1) * pageSize
                      ),
                      total: dataFilteredAndSorted.length,
                    })}
                  </Text>
                </Flex>
                <Flex className="flex-pagination-participate-page">
                  <Button
                    className="button-arrow-page-participate-page"
                    cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
                    onClick={() => previousPage()}
                  >
                    <LeftArrow active={pageIndex > 0} />
                  </Button>
                  <Flex className="flex-pagination-buttons-participate-page">
                    {getPaginationButtons(
                      Math.ceil(dataFilteredAndSorted.length / pageSize)
                    )}
                  </Flex>
                  <Flex
                    onClick={() => nextPage()}
                    className={"flex-change-page-participate-page"}
                    opacity={
                      pageIndex <
                      Math.ceil(dataFilteredAndSorted.length / pageSize) - 1
                        ? 1
                        : 0.84
                    }
                    _hover={{
                      cursor:
                        pageIndex <
                        Math.ceil(dataFilteredAndSorted.length / pageSize) - 1
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <Button
                      onClick={() => nextPage()}
                      className="button-arrow-page-participate-page"
                      cursor={
                        pageIndex < dataFilteredAndSorted.length - 1
                          ? "pointer"
                          : "not-allowed"
                      }
                    >
                      <RightArrow
                        active={pageIndex < dataFilteredAndSorted.length - 1}
                      />
                    </Button>
                  </Flex>
                </Flex>
                <Box></Box>
              </Flex>
            ) : (
              <Flex
                className="responsive-bottom-flex-participate-page"
                mt={0}
                pt={5}
                borderBottomLeftRadius={{ base: "18px", md: "0px" }}
                borderBottomRightRadius={{ base: "18px", md: "0px" }}
                backgroundColor="var(--CLEAR-BACKGROUND)"
              >
                <Flex
                  onClick={() => previousPage()}
                  className={`flex-change-page-participate-page`}
                  opacity={pageIndex > 0 ? 1 : 0.84}
                  _hover={{ cursor: pageIndex > 0 ? "pointer" : "not-allowed" }}
                >
                  <Button
                    className="button-arrow-page-participate-page"
                    cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
                  >
                    <LeftArrow active={pageIndex > 0} />
                  </Button>
                </Flex>
                <Flex className="flex-pagination-buttons-participate-page">
                  {getPaginationButtons(
                    Math.ceil(dataFilteredAndSorted.length / pageSize)
                  )}
                </Flex>
                <Flex
                  onClick={() => nextPage()}
                  className={`flex-change-page-participate-page ${
                    pageIndex <
                    Math.ceil(dataFilteredAndSorted.length / pageSize) - 1
                      ? "flex-change-page-opacity-total-participate-page"
                      : "flex-change-page-opacity-partial-participate-page"
                  }`}
                  opacity={
                    pageIndex <
                    Math.ceil(dataFilteredAndSorted.length / pageSize) - 1
                      ? 1
                      : 0.84
                  }
                  _hover={{
                    cursor:
                      pageIndex <
                      Math.ceil(dataFilteredAndSorted.length / pageSize) - 1
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  <Button
                    className="button-arrow-page-participate-page"
                    cursor={
                      pageIndex < dataFilteredAndSorted.length - 1
                        ? "pointer"
                        : "not-allowed"
                    }
                  >
                    <RightArrow
                      active={pageIndex < dataFilteredAndSorted.length - 1}
                    />
                  </Button>
                </Flex>
              </Flex>
            )}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default TableOfCurrentDecision;
