import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";

export default function ModalInviteParticipants({
  isOpen,
  onClose,
  currentDecision,
}) {
  const { t, tMarkdown } = useMarkdownTranslations();
  const [selectedOption, setSelectedOption] = useState("");

  const walletLink = "https://token-test.staging.ethelo.net/login/wallet";

  const insertLink = (text, link) => {
    const parts = text.split(link);
    return (
      <>
        {parts[0]}
        <Link href={link} isExternal>
          {link}
        </Link>
        {parts[1]}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
      >
        <ModalBody paddingY={4}>
          <Tabs isFitted variant="unstyled">
            <Flex justifyContent="center" mb="1em">
              <TabList maxWidth="500px" width="100%">
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  {t("decision.participants.invite")}
                </Tab>
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  {t("decision.participant.upload")}
                </Tab>
              </TabList>
            </Flex>
            <TabPanels>
              <TabPanel>
                <Text className="invite-participants-text-modal-invite-participants">
                  {tMarkdown("invite.intro_html")}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel
                      htmlFor="first-name"
                      className="input-label-text"
                    >
                      {t("participant.name")}
                    </FormLabel>
                    <Input
                      id="first-name"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel
                      htmlFor="email"
                      className="input-label-text"
                    >
                      {t("input.header.email_or_wallet")}
                    </FormLabel>
                    <Input
                      id="email"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 2" }}
                    position="relative"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="influence"
                        className="input-label-text"
                      >
                        {t("input.influence.title")}
                      </FormLabel>
                      <Tooltip
                        label={t("tooltip.influence")}
                        aria-label={t("accessibility.information.influence")}
                        placement="top-end"
                        hasArrow
                      >
                        <Box
                          as="span"
                          cursor="pointer"
                          fontWeight="bold"
                          color="white"
                          _hover={{ color: "white" }}
                          background="black"
                          borderRadius="full"
                          height={6}
                          width={6}
                          textAlign="center"
                        >
                          !
                        </Box>
                      </Tooltip>
                    </Box>
                    <Input
                      id="influence"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>
                </SimpleGrid>
                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  mt={4}
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>

                <Flex align="center" mt={1} mb={2}>
                  <Text
                    mt={1}
                    mb={1}
                    className="roles-modal-invite-participants"
                  >
                    {t("participant.role.plural")}
                  </Text>
                  <Tooltip
                    label={t("tooltip.roles")}
                    aria-label={t("accessibility.information.roles")}
                    placement="top-end"
                    hasArrow
                  >
                    <Box
                      as="span"
                      ml={2}
                      cursor="pointer"
                      fontWeight="bold"
                      color="white"
                      _hover={{ color: "white" }}
                      background="black"
                      borderRadius="full"
                      height={6}
                      width={6}
                      textAlign="center"
                      lineHeight="24px"
                    >
                      !
                    </Box>
                  </Tooltip>
                </Flex>
                <Flex direction="row" gap={4}>
                  <Checkbox
                    isChecked={selectedOption === "email"}
                    onChange={() => setSelectedOption("email")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      {t("account.admin")}
                    </span>
                  </Checkbox>

                  <Checkbox
                    isChecked={selectedOption === "link"}
                    onChange={() => setSelectedOption("link")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                        outline: "none",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                        outline: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      {t("account.moderator")}
                    </span>
                  </Checkbox>

                  <Checkbox
                    isChecked={selectedOption === "wallet"}
                    onChange={() => setSelectedOption("wallet")}
                    className="checkbox-text-modal-invite-participants"
                    size="lg"
                    iconSize="1.5em"
                    sx={{
                      "span.chakra-checkbox__control": {
                        width: "24px",
                        height: "24px",
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        boxShadow: "none",
                        _checked: {
                          bg: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                      },
                      "span.chakra-checkbox__control[data-checked]": {
                        backgroundColor: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                      "span.chakra-checkbox__control:focus": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <span className="checkbox-text-modal-invite-participants">
                      {t("account.participant")}
                    </span>
                  </Checkbox>
                </Flex>
              </TabPanel>

              <TabPanel>
                <Text
                  mb={4}
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  {t("panel.info.file_or_text")}
                </Text>

                <Text
                  mt={4}
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  {insertLink(t("panel.info.signup_wallet", {
                    link: walletLink,
                  }), walletLink)}
                </Text>

                <Text
                  textAlign="center"
                  className="text-modal-invite-participants"
                >
                  {t("link.example")}{" "}
                  <Link href="http://www.examplelink.com" isExternal>
                    www.examplelink.com
                  </Link>
                </Text>

                <Flex
                  mb={4}
                  justifyContent="center"
                  alignItems="center"
                  borderTop="2px var(--INPUT-BACKGROUND) solid"
                  pt={8}
                >
                  <Input
                    className="input-modal-invite-participants"
                    type="file"
                    mr={2}
                    variant="outline"
                    sx={{
                      "::-webkit-file-upload-button": {
                        display: "none",
                        marginTop: "-8px !important",
                      },
                      height: "50px",
                      padding: "0.5em",
                    }}
                  />
                  <Button className="submit-button-modal-invite-participants">
                    {t("btn.upload_file")}
                  </Button>
                </Flex>

                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => onClose()}
            >
              {t("btn.go_back")}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => onClose()}
            >
              {t("btn.confirm")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
