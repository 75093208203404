// DecisionPanelHost.jsx
import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex,
  Text,
  Button,
  useDisclosure,
  InputGroup,
  InputRightElement,
  Image,
  Input,
  Select,
  Circle,
  Link
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModalEditDesc from "components/ModalEditDesc";
import ModalInviteGroup from "components/ModalInviteGroup";
import ModalCreateGroupDecision from "components/ModalCreateGroupDecision";
import TableOfGroupInvites from "pages/TableOfGroupInvites";
import TableOfGroupMembers from "pages/TableOfGroupMembers";
import GroupsDecisions from "components/GroupsDecisions";
import GroupDescription from "pages/GroupDescription";
import ModalDeleteDescGroup from "components/ModalDeleteDescGroup";
import searchIcon from "../../assets/images/search.png";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useCurrentGroupsContext } from "features/groups/contexts/groups-provider";
import { useCurrentUserContext } from "features/user/contexts/user-provider";

export default function DecisionPanelHost() {
  const { slug } = useParams();
  const [sortBy, setSortBy] = useState("none");
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const { groupsHosted, groupsData } = useCurrentGroupsContext();
  const [currentGroup, setCurrentGroup] = useState({});

  //let isAdmin = true;
  // useDisclosure for Edit Group modal

  const listOfStatus = [
    { label: t("decision.status.all"), value: "All" },
    { label: t("decision.status.open"), value: "Open" },
    { label: t("decision.status.unpublished"), value: "Unpublished" },
    { label: t("decision.status.coming_soon"), value: "Coming Soon" },
    { label: t("decision.status.finished"), value: "Finished" },
    { label: t("decision.status.shut_down"), value: "Shut Down" },
  ];

  const listOfSortsTableOfGroupMembers = [
    { label: "Sort by", value: "none" },
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Web3 Address", value: "web3Address" },
    { label: "Role", value: "role" },
    { label: "Last Invite Sent", value: "lastInviteSent" },
  ];

  const listOfSortsDecisions = [
    { label: "Sort by", value: "none" },
    { label: "Title", value: "title" },
    { label: "Url", value: "url" },
  ];

  const returnListOfSortsData = (currentTab) => {
    if (currentTab === 1) {
      return listOfSortsDecisions;
    } else if (currentTab === 3 || currentTab === 2) {
      return listOfSortsTableOfGroupMembers;
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const {
    isOpen: isEditGroupOpen,
    onOpen: onEditGroupOpen,
    onClose: onEditGroupClose,
  } = useDisclosure();

  // useDisclosure for Create Decision modal
  const {
    isOpen: isCreateDecisionOpen,
    onOpen: onCreateDecisionOpen,
    onClose: onCreateDecisionClose,
  } = useDisclosure();

  // useDisclosure for Invite Group Member modal
  const {
    isOpen: isInviteGroupOpen,
    onOpen: onInviteGroupOpen,
    onClose: onInviteGroupClose,
  } = useDisclosure();

  // useDisclosure for Invite Group Member modal
  const {
    isOpen: isDeleteGroupOpen,
    onOpen: onDeleteGroupOpen,
    onClose: onDeleteGroupClose,
  } = useDisclosure();

  const [currentTab, setCurrentTab] = useState(0); // State to track the current tab

  useEffect(() => {
    try {
      const currentGroupElement = groupsData.find(
        (group) => group?.attributes?.slug === slug
      );
      console.log("currentGroupElement", currentGroupElement);
      setCurrentGroup(currentGroupElement);
    } catch (error) {}
  }, [groupsData, slug]);

  useEffect(() => {
    try {
      const slugList = groupsHosted.map((group) => group?.attributes?.slug);
      if (slugList.includes(slug)) {
        setIsAdmin(true);
      } else {
        // setIsAdmin(false);
      }
    } catch (error) {
      // setIsAdmin(false); // Optionally set a default state in case of error
    }
  }, [groupsHosted, slug]);

  useEffect(() => {
    // Obtener el último segmento de la ruta
    const pathSegments = location.pathname.split("/");
    const lastSegment = pathSegments[pathSegments.length - 1];

    switch (lastSegment) {
      case "description":
        setCurrentTab(0);
        break;
      case "decisions":
        setCurrentTab(1);
        break;
      case "members":
        setCurrentTab(2);
        break;
      case "invites":
        setCurrentTab(3);
        break;
      default:
        setCurrentTab(0);
    }
  }, [location.pathname]);

  const handleOpenModal = () => {
    switch (currentTab) {
      case 0:
        onEditGroupOpen();
        break;
      case 1:
        navigate("/create-decisions");
        break;
      case 2:
      case 3:
        onInviteGroupOpen();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="main-box-hosted-decisions-page">
      <Box className="body-box-hosted-decisions-page">
        <Flex justify="space-between" align="center" mb={4}>
          <Flex
            textAlign="left"
            mb={2}
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Circle
              size="60px"
              className="group-identification-circle"
              color="white"
              mr={4}
            >
              {currentGroup?.attributes?.title?.charAt(0)?.toUpperCase()}
            </Circle>
            <Box>
              <Text className="group-card-header">
                {currentGroup?.attributes?.title}
              </Text>
              <Link className="group-card-link">
                {currentGroup?.attributes?.url || "https://www.testing.com"}
              </Link>
            </Box>
          </Flex>
          {isAdmin && (
            <Button className="main-button" onClick={handleOpenModal}>
              {currentTab === 0 && "Edit"}
              {currentTab === 1 && "Create a New Decision"}
              {(currentTab === 2 || currentTab === 3) && "Invite Group Member"}
            </Button>
          )}
        </Flex>

        <Tabs
          index={currentTab}
          onChange={(index) => setCurrentTab(index)}
          variant="unstyled"
        >
          <TabList
            display="flex"
            justifyContent="space-around"
            w={"fit-content"}
            mb={4}
            className="tab-list-hosted-decisions-page"
          >
            <Tab
              className="tab-modal-invite-participants"
              _selected={{
                borderBottom: "3px var(--MAIN-GREY) solid !important",
                outline: "none !important",
              }}
            >
              Description
            </Tab>
            <Tab
              className="tab-modal-invite-participants"
              _selected={{
                borderBottom: "3px var(--MAIN-GREY) solid !important",
                outline: "none !important",
              }}
            >
              Decisions
            </Tab>
            {isAdmin && (
              <>
                <Tab
                  className="tab-modal-invite-participants"
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Members
                </Tab>
                <Tab
                  className="tab-modal-invite-participants"
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Invites
                </Tab>
              </>
            )}
          </TabList>

          {/* Input Field */}
          {currentTab !== 0 && (
            <Flex
              className="flex-participate-participate-page"
              px={"22px"}
              gap={4}
            >
              <Flex className="first-box-participate-page">
                <InputGroup>
                  <InputRightElement>
                    <Image
                      src={searchIcon}
                      className="input-right-element-image-create-decision-page"
                      alt={t("input.placeholder.search")}
                    />
                  </InputRightElement>
                  <Input
                    className="input-participate-page"
                    placeholder={t("input.placeholder.search")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>
              </Flex>
              <Flex className="inside-flex-participate-page" gap={4}>
                <Box className="input-box-participate-page">
                  <InputGroup gap={4}>
                    {currentTab === 1 && (
                      <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="select-participate-page"
                      >
                        {listOfStatus.map((currentStatus, i) => (
                          <option key={i} value={currentStatus.value}>
                            {currentStatus.label}
                          </option>
                        ))}
                      </Select>
                    )}
                    <Select
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      className="select-participate-page"
                    >
                      {returnListOfSortsData(currentTab).map(
                        (currentSort, i) => (
                          <option key={i} value={currentSort.value}>
                            {currentSort.label}
                          </option>
                        )
                      )}
                    </Select>
                  </InputGroup>
                </Box>
              </Flex>
            </Flex>
          )}

          <TabPanels>
            <TabPanel>
              <GroupDescription currentGroup={currentGroup}/>
            </TabPanel>
            <TabPanel>
              <GroupsDecisions
                search={search}
                sortBy={sortBy}
                status={status}
                isAdmin={isAdmin}
              />
            </TabPanel>
            {isAdmin && (
              <TabPanel style={{ padding: "0" }}>
                <TableOfGroupMembers search={search} sortBy={sortBy} />
              </TabPanel>
            )}
            {isAdmin && (
              <TabPanel>
                <TableOfGroupInvites search={search} sortBy={sortBy} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>

        <ModalEditDesc
          isOpen={isEditGroupOpen}
          onClose={onEditGroupClose}
          onDeleteGroupOpen={onDeleteGroupOpen}
        />
        <ModalInviteGroup
          isOpen={isInviteGroupOpen}
          onClose={onInviteGroupClose}
        />
        <ModalCreateGroupDecision
          isOpen={isCreateDecisionOpen}
          onClose={onCreateDecisionClose}
        />
        <ModalDeleteDescGroup
          isOpen={isDeleteGroupOpen}
          onClose={onDeleteGroupClose}
        />
      </Box>
    </Box>
  );
}
