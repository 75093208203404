import { useEffect, useState } from "react";
import {
    Flex,
    Input,
    Button,
    InputGroup,
    Stack,
    Text,
    Box,
    FormControl,
    Center,
    Link,
    Checkbox,
    Image,
    Grid,
    InputLeftElement
} from "@chakra-ui/react";
import connectWallet from '../../features/web3/utils/connectMetamask';
import personalSign from '../../features/web3/utils/personalSign';
import authApi from '../../api/auth';
import isMetaMaskInstalled from '../../features/web3/utils/isMetaMaskInstalled';
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations"
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getErrorMessage } from "utils";
import "./styles.css";

import MetamaskLogo from 'assets/images/metamask_logo.svg';
import politySideImage from "../../assets/images/polity_side.png";
import usernameIcon from "../../assets/images/username_icon.png";
import emailIcon from "../../assets/images/email_icon.png";

const Signup = () => {
    const hasMetamaskExtension = isMetaMaskInstalled();
    const [receiveNotifications, setReceiveNotifications] = useState(false);
    const [agreeWithTerms, setAgreeWithTerms] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const { t } = useMarkdownTranslations();
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState("");
    const [emailErrorText, setEmailErrorText] = useState("");
    const [errorAgreeWithTerms, setErrorAgreeWithTerms] = useState(false);
    const [web3Error, setWeb3Error] = useState(false);
    const [web3TextError, setWeb3TextError] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const registerInApp = async (name, email) => {
        if (!agreeWithTerms) {
            setErrorAgreeWithTerms(true);
            return;
        }

        setErrorAgreeWithTerms(false);

        const data = { name, email, terms: agreeWithTerms, ethelo_notifications: receiveNotifications };
        authApi
            .signup({ user: data })
            .then(() => {
                navigate('/', { replace: true });
            })
            .catch(e => {
                e?.data?.errors?.forEach(currentError => {
                    if (currentError.source.pointer === "/data/attributes/username") {
                        setNameError(true);
                        setNameErrorText(getErrorMessage(currentError.detail));
                    }
                    else if (currentError.source.pointer === "/data/attributes/email") {
                        setEmailError(true);
                        setEmailErrorText(getErrorMessage(currentError.detail));
                    }
                });
            });
    };

    const connectWithMetamask = async (nonce, publicAddress, data) => {
        const message = `Ethelo Sign Up,${Date.now()},${nonce}`;
        const { signature } = await personalSign({ message, publicAddress });
        if (signature) {
            const user = {
                ...data,
                terms: agreeWithTerms,
                ethelo_notifications: receiveNotifications,
                public_address: publicAddress,
                message,
                signature,
            };
            authApi["join"]({ user })
                .then(() => {
                    navigate('/', { replace: true });
                })
                .catch((e) => {
                    e?.data?.errors?.forEach(currentError => {

                    });
                });
        }
    };

    const registerInAppWithWeb3 = async (name, email) => {
        if (!agreeWithTerms) {
            setErrorAgreeWithTerms(true);
            return;
        }

        setWeb3Error(false);

        setErrorAgreeWithTerms(false);

        const data = { name, email };
        const { publicAddress } = await connectWallet();
        if (publicAddress) {
            authApi.nonce({ user: { public_address: publicAddress } })
                .then(response => {
                    connectWithMetamask(response.nonce, publicAddress, data);
                })
                .catch(e => {
                    e?.data?.errors?.forEach(currentError => {
                        if (currentError.source.pointer === "/data/attributes/username") {
                            setNameError(true);
                            setNameErrorText(getErrorMessage(currentError.detail));
                        }
                        else if (currentError.source.pointer === "/data/attributes/email") {
                            setEmailError(true);
                            setEmailErrorText(getErrorMessage(currentError.detail));
                        } else if (currentError.source.pointer === "/data/attributes/auth") {
                            setWeb3Error(true);
                            setWeb3TextError(getErrorMessage(currentError.detail));
                        }
                    });
                });
        }
    };

    useEffect(() => {
        setEmailError(false);
    }, [email]);

    useEffect(() => {
        setNameError(false);
    }, [name]);

    useEffect(() => {
        setErrorAgreeWithTerms(false);
    }, [agreeWithTerms]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Flex className="flexColumn-signup" id="main" maxHeight="100vh">
            <Grid className="grid-signup">
                {windowWidth > 768 && <Image src={politySideImage} className="side-image-signup" minH="100vh" maxH="100vh" />}
                <Flex className="flexCentered-signup" width="100%" maxHeight="100vh">
                    <Stack className="stackForm-signup" role="form" aria-label={t("accessibility.authentication.signup_form")} width="100%" minH="100vh" maxH="100vh">
                        <Box className="register-text-without-metamask">{t("authentication.register")}</Box>
                        <Box className="welcome-text-without-metamask">{t("company.welcome")}</Box>
                        <FormControl>
                            <Center>
                                <InputGroup>
                                    <Flex className="flexColumnForErrorMessage-signup" direction="column">
                                        <InputLeftElement className="input-left-element-without-metamask" children={<Image src={usernameIcon} alt={t("input.placeholder.username")} />} />
                                        <Input type="text" placeholder={t("input.placeholder.username")} aria-labelledby="name" value={name} onChange={e => setName(e.target.value)} className={"input-signup"} />
                                        {nameError && <Text className="errorText-signup" aria-label={nameErrorText}>{nameErrorText}</Text>}
                                    </Flex>
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <FormControl>
                            <Text className="inputText-signup" id="email" role="heading" aria-level="3">{t("authentication.email_address")}</Text>
                            <Center>
                                <InputGroup>
                                    <Flex className="flexColumnForErrorMessage-signup">
                                        <InputLeftElement className="input-left-element-without-metamask" children={<Image src={emailIcon} alt={t("authentication.email_address")} />} />
                                        <Input placeholder={t("authentication.email_address")} type="email" aria-labelledby={t('participant.email')} value={email} onChange={e => setEmail(e.target.value)} className={"input-signup"} />
                                        {emailError && <Text className="errorText-signup" aria-label={emailErrorText}>{emailErrorText}</Text>}
                                    </Flex>
                                </InputGroup>
                            </Center>
                        </FormControl>
                        <Flex className="flexboxOfCheckbox-signup">
                            <Checkbox className="checkbox-signup" size="lg" id="update me" isChecked={receiveNotifications} onChange={e => setReceiveNotifications(e.target.checked)} aria-checked="false">
                                <Box className="checkbox-text-signup">
                                    {t("checkbox.update_me")}
                                </Box>
                            </Checkbox>
                        </Flex>
                        <Flex className="flexboxOfCheckbox-signup">
                            <Checkbox className="checkbox-signup" size="lg" id="agree with terms" isChecked={agreeWithTerms} onChange={e => setAgreeWithTerms(e.target.checked)} aria-checked="false">
                                <Box className="checkbox-text-signup">
                                    {t("checkbox.accept_generals")} <Link className="terms-link">{t("checkbox.terms_and_conditions")}</Link> {t("checkbox.of_use")}
                                </Box>
                            </Checkbox>
                        </Flex>
                        {errorAgreeWithTerms && <Text className="errorTermsAndConditions-signup" aria-label={t("accessibility.authentication.accept_terms")}>{t("authentication.accept_terms")}</Text>}
                        <Flex className="flexboxWithInformationOfPasswordRecover-signup">
                            <Text className="passwordEmailedText-signup" role="heading" aria-level="4">
                                {t("authentication.password.will_be_emailed")}
                            </Text>
                        </Flex>
                        {hasMetamaskExtension &&
                            <Flex direction="column">
                                <Button onClick={() => registerInAppWithWeb3(name, email)} className="button-metamask-signup">
                                    <Image className="metamask-logo-signup" src={MetamaskLogo} /> {t("authentication.web3.register")}
                                </Button>
                                {web3Error && <Text className="errorText-signup" aria-label={web3TextError}>{web3TextError}</Text>}
                            </Flex>
                        }
                        <Button onClick={() => registerInApp(name, email)} className="button-signup">
                            {t("btn.register")}
                        </Button>
                        <Flex className="bottom-signup">
                            <Box className="login-to-account-signup">{t("authentication.question.already_have_account")}</Box>
                            <Link as={RouterLink} className="login-link-signup" to="/">{t("authentication.login_to_account")}</Link>
                        </Flex>
                    </Stack>
                </Flex>
            </Grid>
        </Flex>
    );
};

export default Signup;