// ModalInviteGroup.jsx
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function ModalInviteGroup({ isOpen, onClose }) {
  const { t } = useMarkdownTranslations();

  // State for form inputs
  const [firstName, setFirstName] = useState("");
  const [emailOrWallet, setEmailOrWallet] = useState("");
  const [linkDecision, setLinkDecision] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [role, setRole] = useState("");

  // State for upload participants tab
  const [fileUpload, setFileUpload] = useState(null);
  const [linkDecisionUpdate, setLinkDecisionUpdate] = useState("");
  const [inviteSent, setInviteSent] = useState(false);

  const handleInvite = () => {
    // Process the invitation logic here

    // After processing, set inviteSent to true and reset inputs
    setInviteSent(true);
    resetForm();
  };

  const resetForm = () => {
    setFirstName("");
    setEmailOrWallet("");
    setLinkDecision("");
    setSendEmail(false);
    setRole("");
    setFileUpload(null);
    setLinkDecisionUpdate("");
  };

  const handleClose = () => {
    // Reset the form when closing the modal
    resetForm();
    setInviteSent(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
      >
        <ModalBody paddingY={4}>
          <Tabs isFitted variant="unstyled">
            <Flex justifyContent="center" mb="1em">
              <TabList maxWidth="500px" width="100%">
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Invite Members
                </Tab>
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Upload Participants
                </Tab>
              </TabList>
            </Flex>
            {inviteSent && (
              <Flex
                justifyContent="center"
                alignItems="center"
                borderTop="1px var(--MAIN-GREY) solid !important"
              >
                <Box
                  mt={6}
                  textAlign="left"
                  width={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
                >
                  <Text fontSize="lg" fontWeight="bold">
                    New Invite Added! An Email will be sent immediately.
                  </Text>
                  <Text>You can create another invite now.</Text>
                </Box>
              </Flex>
            )}
            <TabPanels>
              <TabPanel>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={0}>
                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 1" }}
                  >
                    <FormLabel
                      htmlFor="first-name"
                      className="input-label-text"
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        <span>{t("participant.name")}</span>
                        <span>*</span>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="first-name"
                      className="input-modal-invite-participants"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 1" }}
                  >
                    <FormLabel htmlFor="email" className="input-label-text">
                      <Flex justifyContent="space-between" alignItems="center">
                        <span>{t("input.header.email_or_wallet")}</span>
                        <span>*</span>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="email"
                      className="input-modal-invite-participants"
                      value={emailOrWallet}
                      onChange={(e) => setEmailOrWallet(e.target.value)}
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 2" }}
                    position="relative"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="link-decision"
                        className="input-label-texts"
                      >
                        Link Decision in Invite
                      </FormLabel>
                    </Box>
                    <Input
                      id="link-decision"
                      className="input-modal-invite-participants"
                      value={linkDecision}
                      onChange={(e) => setLinkDecision(e.target.value)}
                    />
                  </FormControl>
                </SimpleGrid>
                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  mt={4}
                  isChecked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>

                <Flex align="center" mt={1} mb={2}>
                  <Text
                    mt={1}
                    mb={1}
                    className="roles-modal-invite-participants"
                  >
                    {t("participant.role.plural")}
                  </Text>
                  <Tooltip
                    label={t("tooltip.roles")}
                    aria-label={t("accessibility.information.roles")}
                    placement="top-end"
                    hasArrow
                  >
                    <Box
                      as="span"
                      ml={2}
                      cursor="pointer"
                      fontWeight="bold"
                      color="white"
                      _hover={{ color: "white" }}
                      background="black"
                      borderRadius="full"
                      height={6}
                      width={6}
                      textAlign="center"
                      lineHeight="24px"
                    >
                      !
                    </Box>
                  </Tooltip>
                </Flex>

                <RadioGroup
                  onChange={setRole}
                  value={role}
                  className="checkbox-text-modal-invite-participants"
                >
                  <Flex direction="row" gap={4}>
                    <Radio
                      value="admin"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.admin")}
                      </span>
                    </Radio>

                    <Radio
                      value="moderator"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.moderator")}
                      </span>
                    </Radio>

                    <Radio
                      value="member"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.member")}
                      </span>
                    </Radio>
                  </Flex>
                </RadioGroup>
              </TabPanel>

              <TabPanel>
                <Flex
                  mb={4}
                  justifyContent="center"
                  alignItems="center"
                  borderTop="2px var(--INPUT-BACKGROUND) solid"
                  pt={8}
                >
                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="file-upload"
                        className="input-label-texts"
                      >
                        Upload File
                      </FormLabel>
                    </Box>
                    <Flex alignItems="center" gap={2}>
                      <Input
                        className="input-modal-invite-participants"
                        type="file"
                        variant="outline"
                        onChange={(e) => setFileUpload(e.target.files[0])}
                        sx={{
                          "::-webkit-file-upload-button": {
                            display: "none",
                            marginTop: "-8px !important",
                          },
                          height: "50px",
                          padding: "0.5em",
                        }}
                      />
                      <Button
                        className="submit-button-modal-invite-participants"
                        onClick={() => {
                        }}
                      >
                        {t("btn.upload_file")}
                      </Button>
                    </Flex>
                  </FormControl>
                </Flex>
                <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      htmlFor="link-decision-update"
                      className="input-label-texts"
                    >
                      Link Decision to Update
                    </FormLabel>
                  </Box>
                  <Flex alignItems="center" gap={2}>
                    <Input
                      id="link-decision-update"
                      className="input-modal-invite-participants"
                      value={linkDecisionUpdate}
                      onChange={(e) => setLinkDecisionUpdate(e.target.value)}
                    />
                  </Flex>
                </FormControl>
                <Checkbox
                  mt={2}
                  size="lg"
                  iconSize="1.5em"
                  isChecked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button className="first-button-modal-invite" onClick={handleClose}>
              {t("btn.go_back")}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={handleInvite}
            >
              {t("btn.confirm")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
/*import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function ModalInviteGroup({ isOpen, onClose }) {
  const { t, tMarkdown } = useMarkdownTranslations();
  const [selectedOption, setSelectedOption] = useState("");
  const [inviteSent, setInviteSent] = useState(false);

  const walletLink = "https://token-test.staging.ethelo.net/login/wallet";

  const handleInvite = () => {
    setInviteSent(true);
  };

  const insertLink = (text, link) => {
    const parts = text.split(link);
    return (
      <>
        {parts[0]}
        <Link href={link} isExternal>
          {link}
        </Link>
        {parts[1]}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
      >
        <ModalBody paddingY={4}>
          <Tabs isFitted variant="unstyled">
            <Flex justifyContent="center" mb="1em">
              <TabList maxWidth="500px" width="100%">
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Invite Members
                </Tab>
                <Tab
                  className={`tab-modal-invite-participants`}
                  _selected={{
                    borderBottom: "3px var(--MAIN-GREY) solid !important",
                    outline: "none !important",
                  }}
                >
                  Upload Participants
                </Tab>
              </TabList>
            </Flex>
            {inviteSent && (
              <Flex
                justifyContent="center"
                alignItems="center"
                borderTop="1px var(--MAIN-GREY) solid !important"
              >
                <Box
                  mt={6}
                  textAlign="left"
                  width={{ base: "90%", sm: "80%", md: "70%", lg: "50%" }}
                >
                  <Text fontSize="lg" fontWeight="bold">
                    New Invite Added! An Email will be sent immediately.
                  </Text>
                  <Text>You can create another invite now.</Text>
                </Box>
              </Flex>
            )}
            <TabPanels>
              <TabPanel>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={0}>
                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel
                      htmlFor="first-name"
                      className="input-label-text"
                    >
                      <Flex justifyContent="space-between" alignItems="center">
                        <span>{t("participant.name")}</span>
                        <span>*</span>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="first-name"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <FormLabel htmlFor="email" className="input-label-text">
                      <Flex justifyContent="space-between" alignItems="center">
                        <span>{t("input.header.email_or_wallet")}</span>
                        <span>*</span>
                      </Flex>
                    </FormLabel>
                    <Input
                      id="email"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>

                  <FormControl
                    gridColumn={{ base: "span 1", md: "span 2" }}
                    position="relative"
                  >
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="influence"
                        className="input-label-texts"
                      >
                        Link Decision in Invite
                      </FormLabel>
                    </Box>
                    <Input
                      id="influence"
                      className="input-modal-invite-participants"
                    />
                  </FormControl>
                </SimpleGrid>
                <Checkbox
                  size="lg"
                  iconSize="1.5em"
                  mt={4}
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>

                <Flex align="center" mt={1} mb={2}>
                  <Text
                    mt={1}
                    mb={1}
                    className="roles-modal-invite-participants"
                  >
                    {t("participant.role.plural")}
                  </Text>
                  <Tooltip
                    label={t("tooltip.roles")}
                    aria-label={t("accessibility.information.roles")}
                    placement="top-end"
                    hasArrow
                  >
                    <Box
                      as="span"
                      ml={2}
                      cursor="pointer"
                      fontWeight="bold"
                      color="white"
                      _hover={{ color: "white" }}
                      background="black"
                      borderRadius="full"
                      height={6}
                      width={6}
                      textAlign="center"
                      lineHeight="24px"
                    >
                      !
                    </Box>
                  </Tooltip>
                </Flex>

                <RadioGroup
                  onChange={setSelectedOption}
                  value={selectedOption}
                  className="checkbox-text-modal-invite-participants"
                >
                  <Flex direction="row" gap={4}>
                    <Radio
                      value="email"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.admin")}
                      </span>
                    </Radio>

                    <Radio
                      value="link"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.moderator")}
                      </span>
                    </Radio>

                    <Radio
                      value="wallet"
                      size="lg"
                      className="checkbox-text-modal-invite-participants"
                      sx={{
                        "span.chakra-radio__control": {
                          width: "24px",
                          height: "24px",
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          boxShadow: "none",
                          _checked: {
                            bg: "var(--INPUT-BACKGROUND)",
                            color: "var(--MAIN-GREY)",
                          },
                        },
                        "span.chakra-radio__control[data-checked]": {
                          backgroundColor: "var(--INPUT-BACKGROUND)",
                          color: "var(--MAIN-GREY)",
                        },
                        "span.chakra-radio__control:focus": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      <span className="checkbox-text-modal-invite-participants">
                        {t("account.member")}
                      </span>
                    </Radio>
                  </Flex>
                </RadioGroup>
              </TabPanel>

              <TabPanel>
                <Flex
                  mb={4}
                  justifyContent="center"
                  alignItems="center"
                  borderTop="2px var(--INPUT-BACKGROUND) solid"
                  pt={8}
                >
                  <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                    <Box display="flex" justifyContent="space-between">
                      <FormLabel
                        htmlFor="influence"
                        className="input-label-texts"
                      >
                        File Upload
                      </FormLabel>
                    </Box>
                    <Flex alignItems="center" gap={2}>
                      <Input
                        className="input-modal-invite-participants"
                        type="file"
                        variant="outline"
                        sx={{
                          "::-webkit-file-upload-button": {
                            display: "none",
                            marginTop: "-8px !important",
                          },
                          height: "50px",
                          padding: "0.5em",
                        }}
                      />
                      <Button className="submit-button-modal-invite-participants">
                        {t("btn.upload_file")}
                      </Button>
                    </Flex>
                  </FormControl>
                </Flex>
                <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
                  <Box display="flex" justifyContent="space-between">
                    <FormLabel
                      htmlFor="influence"
                      className="input-label-texts"
                    >
                      Link Decision to Update
                    </FormLabel>
                  </Box>
                  <Flex alignItems="center" gap={2}>
                    <Input
                      className="input-modal-invite-participants"
                      type="file"
                      variant="outline"
                      sx={{
                        "::-webkit-file-upload-button": {
                          display: "none",
                          marginTop: "-8px !important",
                        },
                        height: "50px",
                        padding: "0.5em",
                      }}
                    />{" "}
                  </Flex>
                </FormControl>
                <Checkbox
                  mt={2}
                  size="lg"
                  iconSize="1.5em"
                  sx={{
                    "span.chakra-checkbox__control": {
                      width: "24px",
                      height: "24px",
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      boxShadow: "none",
                      _checked: {
                        bg: "var(--INPUT-BACKGROUND)",
                        color: "var(--MAIN-GREY)",
                      },
                    },
                    "span.chakra-checkbox__control[data-checked]": {
                      backgroundColor: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                    "span.chakra-checkbox__control:focus": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <span className="checkbox-text-modal-invite-participants">
                    {t("checkbox.send_email")}
                  </span>
                </Checkbox>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => {
                setInviteSent(false);
                onClose();
              }}
            >
              {t("btn.go_back")}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={handleInvite}
            >
              {t("btn.confirm")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
*/
