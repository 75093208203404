import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";

export default function ModalLeaveGroup({ isOpen, onClose }) {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "30%", sm: "80%", md: "30%", lg: "30%" }}
        padding={4}
      >
        <ModalBody paddingY={4}>
          <Box 
            display="flex" 
            flexDirection="column" 
            gap={2} 
            alignItems="center"
          >
            <Text fontSize="lg" fontWeight="bold" mb={0}>Do you want to leave this group?</Text>
            <Text fontSize="sm" color="gray.600">Leaving this group will remove you for all decisions.This cannot be undone</Text>
          </Box>
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="gray.300" margin={0} pt={0} marginBottom={2}>
          <Box w="100%" display="flex" flexDirection="column" gap={6} marginTop={4}>
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="second-button-modal-invite"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button
                className="danger-button"
                onClick={() => onClose()}
              >
                Leave
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}