import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Box,
  Textarea,
  Avatar,
  Link,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { Select } from "chakra-react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function ModalEditDesc({ isOpen, onClose, onDeleteGroupOpen }) {
  const { t } = useMarkdownTranslations();
  const closeEditOpenAndOpenDelete = () => {
    onClose();
    onDeleteGroupOpen();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
        padding={4}
      >
        <Box 
          display="flex" 
          flexDirection="column" 
          gap={2} 
          borderBottom="1px" 
          borderColor="gray.300"
          padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>Edit group</Text>
          <Text fontSize="sm" color="gray.600">Edit your group information and configure default decision attributes</Text>
        </Box>
        <ModalBody paddingY={4}>
        <Box p={4}>
      <Flex alignItems="center" gap={4}>
        <Box position="relative">
          <Avatar
            bg="blue.600"
            color="white"
            name="Power Group"
            size="md"
            fontSize="md"
          />
          <Box
            position="absolute"
            bottom={-2}
            right={-2}
            bg="white"
            borderRadius="full"
            py={1}
            px={2}
            boxShadow="sm"
          >
            <FontAwesomeIcon icon={faDownload} size="sm" />
          </Box>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="medium" mb={1}>
            Power Group Name
          </Text>
          <Link
            fontSize="sm"
            color="gray.600"
            textDecoration="underline"
            _hover={{ color: 'blue.500' }}
          >
            Change Logo
          </Link>
        </Box>
      </Flex>
    </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel
                htmlFor="first-name"
                className="input-label-text"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <span>Group Name</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="group-name"
                className="input-modal-invite-participants"
              />
            </FormControl>

            <FormControl gridColumn={{ base: "span 1", md: "span 2" }}>
              <FormLabel htmlFor="email" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>Identifier (your group will be at https://site.com/groups/:identifer)</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="identifier"
                className="input-modal-invite-participants"
              />
            </FormControl>

            <FormControl
              gridColumn={{ base: "span 1", md: "span 3" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="influence"
                  className="input-label-texts"
                >
                  More Info Url
                </FormLabel>
              </Box>
              <Input
                id="influence"
                className="input-modal-invite-participants"
              />
            </FormControl>
            <FormControl
              gridColumn={{ base: "span 1", md: "span 3" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="influence"
                  className="input-label-texts"
                >
                  Group Description
                </FormLabel>
              </Box>
              <Textarea
                className="input-modal-invite-participants"
                w="100%"
              />
            </FormControl>
          </SimpleGrid>
          <Text fontSize="md" fontWeight="bold" mt={4}>Decision Defaults</Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel
                htmlFor="first-name"
                className="input-label-text"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <span>Language</span>
                </Flex>
              </FormLabel>
              <Select
                id="group-name"
                className="select-participate-page"
              >
                <option value="en">English</option>
                <option value="fr">French</option>
              </Select>
            </FormControl>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel
                htmlFor="first-name"
                className="input-label-text"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <span>Time Zone </span>
                </Flex>
              </FormLabel>
              <Select
                id="group-name"
                className="select-participate-page"
              >
                <option value="en">UTC</option>
                <option value="fr">UTC+1</option>
                <option value="es">UTC+2</option>
              </Select>
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Box w="100%" display="flex" flexDirection="column" gap={6}>
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="first-button-modal-invite"
                onClick={() => onClose()}
              >
                {t("btn.go_back")}
              </Button>
              <Button
                className="second-button-modal-invite"
                onClick={() => onClose()}
              >
                {t("btn.confirm")}
              </Button>
            </Flex>
            <Flex w="100%" alignItems="center" gap={4}>
              <Button
                className="danger-button"
                onClick={() => closeEditOpenAndOpenDelete()}
              >
                Delete This Group
              </Button>
              <Box display="flex" alignItems="center" fontSize="sm" color="var(--DANGER-COLOR)">Deleting this group will remove all your decisions and members.This cannot be undone!!!!!</Box>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
