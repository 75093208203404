import { USER } from 'features/user/actions';

export const userInitState = {
  authorized: false,
  isLoaded: false,
  user: null,
};

/* eslint-disable no-fallthrough */
export const userReducer = (state = userInitState, action) => {
  switch (action.type) {
    case USER.LOGIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case USER.FETCH.SUCCESS:
      return {
        ...state,
        authorized: true,
        user: action.payload,
      };
    case 'IS_LOADED':
      return {
        ...state,
        isLoaded: action.payload
      }

    default:
      return state;
  }
};
/* eslint-enable */
