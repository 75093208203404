import React from 'react';
import { Flex, Spinner, Box, Text } from '@chakra-ui/react';
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";

export default function LoadingScreen({ loading }) {
    const { t } = useMarkdownTranslations();

    return (
        <>
            {loading && (
                <Box className='box-loading-screen'>
                    <Flex className='flex-loading-screen'>
                        <Spinner className='spinner-loading-screen' size="xl" />
                        <Text className='text-loading-screen'>{t("support.loading")}...</Text>
                    </Flex>
                </Box>
            )}
        </>
    );
}