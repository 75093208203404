import React, { useState, useMemo, useEffect } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Image, Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Link } from "@chakra-ui/react";
import TemplateList from 'components/TemplateList';
import DecisionCopyList from 'components/DecisionCopyList';
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations"
import { useCurrentDecisionsContext } from 'features/decisions/contexts/decisions-provider';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    useExpanded,
    usePagination,
    useAsyncDebounce
} from 'react-table';
import "./styles.css";
import CreateDecisionHeader from 'components/CreateDecisionHeader';
import searchIcon from "../../assets/images/search.png";
import searchDarkModeIcon from "../../assets/images/search_dark_mode.png";

export default function CreateDecision({ lightMode }) {
    const [textToFilter, setTextToFilter] = useState("");
    const { tableDecisionsHostingData, convertDate } = useCurrentDecisionsContext();
    const { dataOfTable } = tableDecisionsHostingData;
    const { t, tMarkdown } = useMarkdownTranslations();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [tableState, setTableState] = useState(() => {
        const savedState = localStorage.getItem('decisionsCopyTable');
        return savedState ? JSON.parse(savedState) : {};
    });

    const ourGlobalFilterFunction = (rows, ids, query) => {
        return rows.filter(
            (row) =>
                (row.values["title"] && query && row.values["title"].toLowerCase().includes(query.toLowerCase())) ||
                (row.values["group-name"] && query && row.values["group-name"].toLowerCase().includes(query.toLowerCase()))
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: t("decision"),
                accessor: "title",
                Cell: ({ row }) => (
                    <>
                        {dataOfTable[row.id]["current-mode"] !== "Closed" ?
                            <Flex className='title-in-table-create-decision-page'>
                                <Link href={row.original.links.visit} className='link-in-title-in-table-create-decision-page' target="_blank" aria-label={`${dataOfTable[row.id].title} link`}>
                                    {dataOfTable[row.id].title}{" "}
                                </Link>
                            </Flex>
                            :
                            <Flex className='title-in-table-create-decision-page'>
                                <Box>{dataOfTable[row.id].title}{" "}</Box>
                            </Flex>}
                    </>
                ),
            },
            {
                Header: t("decision.header.group"),
                accessor: "group-name",
            },
            {
                Header: t("decision.header.updated_at"),
                accessor: "updated-at",
                Cell: ({ row }) => {
                    const dateFormatted = convertDate(dataOfTable[row.id]["updated-at"]);
                    return (
                        <>
                            {Array.isArray(dateFormatted) ?
                                <Box>
                                    <Box>{dateFormatted[0]} {dateFormatted[1]}</Box>
                                </Box> :
                                <Box>{dateFormatted}</Box>}
                        </>
                    );
                },
            }
        ],
        [dataOfTable, convertDate],
    );

    const tableInstance = useTable(
        {
            columns: columns,
            data: dataOfTable,
            initialState: { ...tableState, pageIndex: 0, pageSize: 6 },
            globalFilter: ourGlobalFilterFunction,
        },
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );

    const {
        setGlobalFilter
    } = tableInstance;

    const onChange = useAsyncDebounce((value) => {
        setTextToFilter(value);
        setGlobalFilter(value || undefined);
    }, 300);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Box className='main-box-create-decision-page' id="main" height="auto">
            <CreateDecisionHeader />
            <Tabs className='tabs-create-decision-page' variant="unstyled">
                <Flex className='flex-tabs-create-decision-page' role='navigation' aria-label={t("accessibility.navigation.tab")} gap={8}>
                    <Box className='input-box-create-decision-page' role='search'>
                        <InputGroup padding={0}>
                            <Input className='input-filter-create-decision-page' value={textToFilter || ""}
                                onChange={(e) => {
                                    setTextToFilter(e.target.value);
                                    onChange(e.target.value);
                                }} placeholder={t("input.placeholder.search")} />
                            <InputRightElement children={<Image src={lightMode ? searchIcon : searchDarkModeIcon} className='input-right-element-image-create-decision-page' alt={t("input.placeholder.search")} width={100} />} />
                        </InputGroup>
                    </Box>
                    <TabList className='tab-list-create-decision-page'>
                        <Tab
                            className={`tab-create-decision-page tab-left-create-decision-page`}
                            _selected={{
                                backgroundColor: "var(--MAIN-GREY)",
                                borderColor: "var(--MAIN-GREY)",
                                color: "var(--MAIN-TEXT)",
                            }}
                        >
                            {t("decision.template.from_template")}
                        </Tab>
                        <Tab
                            className={`tab-create-decision-page tab-right-create-decision-page`}
                            _selected={{
                                backgroundColor: "var(--MAIN-GREY)",
                                borderColor: "var(--MAIN-GREY)",
                                color: "var(--MAIN-TEXT)",
                            }}
                        >
                            {t("decision.copy.existing")}
                        </Tab>
                    </TabList>
                </Flex>
                <TabPanels className='tabs-create-decision-page'>
                    <TabPanel className='tab-panel-create-decision-page'>
                        <TemplateList textToFilter={textToFilter} lightMode={lightMode} windowWidth={windowWidth} />
                    </TabPanel>
                    <TabPanel className='tab-panel-create-decision-page'>
                        <DecisionCopyList textToFilter={textToFilter} lightMode={lightMode} windowWidth={windowWidth} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}