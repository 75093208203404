import React from "react";
import { Box, Image, Text, Button, Flex, useDisclosure } from "@chakra-ui/react";
import HTMLReactParser from "html-react-parser";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";
import ModalCreateDecision from "../ModalCreateDecision";
import standardImage from "assets/images/standard_image.png";

export default function CopyDecisionCard({
  currentDecision,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, image, info, links } = currentDecision;
  const { t } = useMarkdownTranslations();

  const handleVisitClick = () => {
    const url = links.visit ? links.visit : links.self ? links.self : "";
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <ModalCreateDecision
        isOpen={isOpen}
        onClose={onClose}
        decisionData={currentDecision}
      />
      <Box
        className="main-box-copy-decision-card"
        role="region"
        aria-label={t('accessibility.card.template')}
      >
        <Text
          className="text-title-copy-decision-card"
          role="heading"
          aria-level="1"
        >
          {title}
        </Text>
        <Image
          src={image || standardImage}
          alt={t('card.copy')}
          className="image-copy-decision-card"
        />
        <Text
          className="html-text-copy-decision-card"
          role="heading"
          aria-level="2"
        >
          {HTMLReactParser(info)}
        </Text>
        <Flex className="flex-copy-decision-card" gap={8}>
          <Button
            onClick={() => onOpen()}
            className="first-button-copy-decision-card"
            flex="1"
          >
            {t('btn.create')}
          </Button>
          <Button
            flex="1"
            onClick={() => handleVisitClick()}
            className="second-button-copy-decision-card"
          >
            {t('btn.demo')}
          </Button>
        </Flex>
      </Box>
    </>
  );
}