import { Box } from "@chakra-ui/react";
import AccountHeader from "components/AccountHeader";
import ChangePassword from "components/ChangePassword";
import "./styles.css";

const Account = () => {
  return (
    <>
      <Box className="main-box-account" id="main">
        <AccountHeader />
        <ChangePassword />
      </Box>
    </>
  );
};

export default Account;
