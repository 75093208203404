import { GROUPS } from 'features/groups/actions';

export const groupsInitState = {
  groupsData: [],
  groupsInvitations: [],
  groupsJoined: [],
  groupsHosted: [],
  decisionGroups: [],
};

/* eslint-disable no-fallthrough */
export const groupsReducer = (state = groupsInitState, action) => {
  switch (action.type) {
    case GROUPS.GROUPS_DATA:
      return {
        ...state,
        groupsData: action.payload
      }
    case GROUPS.GROUPS_INVITATIONS:
      return {
        ...state,
        groupsInvitations: action.payload
      }
    case GROUPS.GROUPS_JOINED:
      return {
        ...state,
        groupsJoined: action.payload
      }
    case GROUPS.GROUPS_HOSTED:
      return {
        ...state,
        groupsHosted: action.payload
      }
    case GROUPS.GROUPS_DECISIONS:
      return {
        ...state,
        decisionGroups: action.payload
      }
    default:
      return state;
  }
};
