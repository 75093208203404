import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tooltip,
  Box,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function ModalEditInvite({ isOpen, onClose, user }) {
  const { t } = useMarkdownTranslations();
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
        padding={4}
      >
        <Box 
          display="flex" 
          flexDirection="column" 
          gap={2} 
          borderBottom="1px" 
          borderColor="gray.300"
        padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>Edit Invite</Text>
          <Text fontSize="sm" color="gray.600">Edit your group information and configure default decision attributes</Text>
        </Box>
        <ModalBody paddingY={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel
                htmlFor="first-name"
                className="input-label-text"
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("participant.name")}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="first-name"
                className="input-modal-invite-participants"
                value={user?.name || ""}
              />
            </FormControl>

            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel htmlFor="email" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("input.header.email_or_wallet")}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="email"
                className="input-modal-invite-participants"
                value={user?.email || ""}
              />
            </FormControl>

            <FormControl
              gridColumn={{ base: "span 1", md: "span 2" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="influence"
                  className="input-label-texts"
                >
                  Link Decision in Invite
                </FormLabel>
              </Box>
              <Input
                id="influence"
                className="input-modal-invite-participants"
              />
            </FormControl>
          </SimpleGrid>
          <Checkbox
            size="lg"
            iconSize="1.5em"
            mt={4}
            sx={{
              "span.chakra-checkbox__control": {
                width: "24px",
                height: "24px",
                backgroundColor: "var(--INPUT-BACKGROUND)",
                boxShadow: "none",
                _checked: {
                  bg: "var(--INPUT-BACKGROUND)",
                  color: "var(--MAIN-GREY)",
                },
              },
              "span.chakra-checkbox__control[data-checked]": {
                backgroundColor: "var(--INPUT-BACKGROUND)",
                color: "var(--MAIN-GREY)",
              },
              "span.chakra-checkbox__control:focus": {
                boxShadow: "none",
              },
            }}
          >
            <span className="checkbox-text-modal-invite-participants">
              {t("checkbox.send_email")}
            </span>
          </Checkbox>

          <Flex align="center" mt={1} mb={2}>
            <Text
              mt={1}
              mb={1}
              className="roles-modal-invite-participants"
            >
              {t("participant.role.plural")}
            </Text>
            <Tooltip
              label={t("tooltip.roles")}
              aria-label={t("accessibility.information.roles")}
              placement="top-end"
              hasArrow
            >
              <Box
                as="span"
                ml={2}
                cursor="pointer"
                fontWeight="bold"
                color="white"
                _hover={{ color: "white" }}
                background="black"
                borderRadius="full"
                height={6}
                width={6}
                textAlign="center"
                lineHeight="24px"
              >
                !
              </Box>
            </Tooltip>
          </Flex>

          <RadioGroup
            onChange={setSelectedOption}
            value={selectedOption}
            className="checkbox-text-modal-invite-participants"
          >
            <Flex direction="row" gap={4}>
              <Radio
                value="email"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  "span.chakra-radio__control": {
                    width: "24px",
                    height: "24px",
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    boxShadow: "none",
                    _checked: {
                      bg: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                  },
                  "span.chakra-radio__control[data-checked]": {
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    color: "var(--MAIN-GREY)",
                  },
                  "span.chakra-radio__control:focus": {
                    boxShadow: "none",
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t("account.admin")}
                </span>
              </Radio>

              <Radio
                value="link"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  "span.chakra-radio__control": {
                    width: "24px",
                    height: "24px",
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    boxShadow: "none",
                    _checked: {
                      bg: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                  },
                  "span.chakra-radio__control[data-checked]": {
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    color: "var(--MAIN-GREY)",
                  },
                  "span.chakra-radio__control:focus": {
                    boxShadow: "none",
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t("account.moderator")}
                </span>
              </Radio>

              <Radio
                value="wallet"
                size="lg"
                className="checkbox-text-modal-invite-participants"
                sx={{
                  "span.chakra-radio__control": {
                    width: "24px",
                    height: "24px",
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    boxShadow: "none",
                    _checked: {
                      bg: "var(--INPUT-BACKGROUND)",
                      color: "var(--MAIN-GREY)",
                    },
                  },
                  "span.chakra-radio__control[data-checked]": {
                    backgroundColor: "var(--INPUT-BACKGROUND)",
                    color: "var(--MAIN-GREY)",
                  },
                  "span.chakra-radio__control:focus": {
                    boxShadow: "none",
                  },
                }}
              >
                <span className="checkbox-text-modal-invite-participants">
                  {t("account.member")}
                </span>
              </Radio>
            </Flex>
          </RadioGroup>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => onClose()}
            >
              {t("btn.go_back")}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => onClose()}
            >
              {t("btn.confirm")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
