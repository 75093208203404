export default async function personalSign (data) {
  let errorMessage = '';
  const signature = await window.ethereum
    .request({method: 'personal_sign', params: [data.message, data.publicAddress]})
    .catch(err => {
      if (err.code === 4001) {
        errorMessage = 'user.error.reject.metamask.sign';
      } else {
        errorMessage = err;
      }
    });
  return {signature, errorMessage};
}