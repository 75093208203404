import React, { useState } from "react";
import { Box, Text, Link, Flex, Button, Circle, Image, useDisclosure } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import ModalInviteGroup from "../../ModalInviteGroup";
import ModalLeaveGroup from "components/ModalLeaveGroup";
import api from "../../../api/groups";
import logoutOut from "assets/images/logout.png";
import userAdd from "assets/images/user_add.png";
import viewList from "assets/images/view_list.png";
import { useCurrentUserContext } from "features/user/contexts/user-provider";

export default function CardHosting({ group }) {
  const { user } = useCurrentUserContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleInvite = (participant) => {
    // Logic to invite the participant
  };

  const handleDelete = async (groupId) => {
    try {
      await api.deleteGroup(groupId);
      setIsDeleteModalOpen(false);
    } catch (error) {}
  };


  
  return (
    <Box p={4} borderWidth="1px">
      <Flex
        textAlign="left"
        mb={2}
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Circle
          size="60px"
          className="group-identification-circle"
          color="white"
          mr={4}
        >
          {group?.attributes?.title?.charAt(0)?.toUpperCase()}
        </Circle>
        <Box>
          <Text className="group-card-header">{group?.attributes?.title}</Text>
          <Link className="group-card-link">
            {group?.attributes?.url || "https://www.testing.com"}
          </Link>
          <Text className="group-card-subheader">
            {group?.attributes?.["user-count"]} Members
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="flex-start" mb={4} gap={2}>
        <Button className="info-button" as={Box}>
          {group?.attributes?.["decision-count"]} Active Decisions
        </Button>
        <Button className="info-button" as={Box}>{user?.id === group?.relationships?.owner?.data?.id ? "Owner" : "Admin"}</Button>
      </Flex>
      <Box flex="1">
        <Flex justify="space-between" align="center" mb={2} gap={2}>
          <Button
            flex="1"
            className="secondary-button"
            onClick={() => setIsInviteModalOpen(true)}
          >
            <Image src={userAdd} />
          </Button>
          <Button
            flex="1"
            _hover={{ textDecoration: "none" }}
            textDecoration={"none"}
            as={RouterLink}
            to={`/g/${group?.attributes?.slug}/members`}
            className="secondary-button"
          >
            <Image src={viewList} />
          </Button>
          <Button flex="1" onClick={onOpen} className="secondary-button">
            <Image src={logoutOut} />
          </Button>
        </Flex>
        <Flex justify="space-between" align="center" mb={4} gap={2} mt={4}>
          <Button
            flex="1"
            className="main-button"
            _hover={{ textDecoration: "none" }}
            textDecoration={"none"}
            as={RouterLink}
            to={`/g/${group?.attributes?.slug}/decisions`}
          >
            {group?.attributes?.["decision-count"]} Active Decisions
          </Button>
        </Flex>
        <Flex justify="space-between" align="center" gap={2}>
          <Button flex="1" className="main-button" as={RouterLink} to={`/create-decisions`}>
            Create Decision
          </Button>
          <Button
            flex="1"
            className="main-button"
            _hover={{ textDecoration: "none" }}
            textDecoration={"none"}
            as={RouterLink}
            to={`/g/${group?.attributes?.slug}`}
          >
            More Info
          </Button>
        </Flex>
      </Box>
      <ModalInviteGroup
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        onInvite={handleInvite}
      />

      <ModalLeaveGroup isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}
