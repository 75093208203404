import React from 'react';
import useCurrentGroups from 'features/groups/hooks/useCurrentGroups';

const GroupsContext = React.createContext();

const GroupsProvider = ({children}) => {
  const currentGroupsState = useCurrentGroups();
  
  return (
    <GroupsContext.Provider value={currentGroupsState}>
      {children}
    </GroupsContext.Provider>
  );
};

const useCurrentGroupsContext = () => {
  const context = React.useContext(GroupsContext);
  if (context === undefined) {
    throw new Error('useCurrentGroupsContext must be used within a GroupsProvider');
  }
  return context;
};

export { GroupsProvider, useCurrentGroupsContext }
