import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Flex,
  Stack,
  CardBody,
  Text,
  Button,
  Avatar,
  Image,
} from "@chakra-ui/react";
import "./styles.css";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import PaymentItems from "components/PaymentItems";
import standardImage from "../../assets/images/standard_image.png";

export default function HomeDashboard() {
  const { user } = useCurrentUserContext();
  const { t, tMarkdown } = useMarkdownTranslations();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Box className="main-box-home-dashboard">
        <Card className="card-header-home-dashboard" variant="outline">
          <Flex className="flex-center-header-home-dashboard">
            <Avatar
              src={user?.avatar}
              className="avatar-header-home-dashboard"
            />
            <Stack
              className="stack-header-home-dashboard"
              role="main"
              aria-label={t("accessibility.decision.create_card")}
            >
              <CardBody className="card-body-header-home-dashboard">
                <Text className="main-text-header-home-dashboard">
                  {t("account.welcome.back")}
                  {windowWidth > 768 && ","}{" "}
                  <span className="name-text-header-home-dashboard">
                    {user?.name}
                  </span>
                </Text>
                {windowWidth > 768 && (
                  <Text className="secondary-text-header-home-dashboard">
                    {tMarkdown("account.welcome.description_html")}
                  </Text>
                )}
              </CardBody>
            </Stack>
          </Flex>
        </Card>
        <Card className="info-card-home-dashboard">
          <Text className="info-text-header-home-dashboard">
            {t("home.quick_guide.header")}
          </Text>
          <Text className="info-text-body-home-dashboard">
            {tMarkdown("home.quick_guide.description_html")}
          </Text>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("home.participate.header")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown("home.participate.description_html")}
            </Text>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("home.host.header")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown("home.host.description_html")}
            </Text>
          </Card>
          <Card className="secondary-info-card-home-dashboard">
            <Text className="secondary-info-card-header-text-home-dashboard">
              {t("home.create_decision.header")}
            </Text>
            <Text className="secondary-info-card-body-text-home-dashboard">
              {tMarkdown("home.create_decision.description_html")}
            </Text>
          </Card>
        </Card>
        <Card className="products-card-home-dashboard">
          <Text className="products-card-header-text-home-dashboard">
            {tMarkdown("home.products.header")}
          </Text>
          <Text className="products-card-body-text-home-dashboard">
            {tMarkdown("home.products.description_html")}
          </Text>
          <PaymentItems />
        </Card>
        <Card className="bottom-card-home-dashboard">
          <Text className="bottom-card-header-text-home-dashboard">
            {t("home.latest_updates.header")}
          </Text>
          <Text className="bottom-card-body-text-home-dashboard">
            {tMarkdown("home.latest_updates.description_html")}
          </Text>
          <Flex
            direction={{ base: "column", md: "row" }}
            gap={8}
            wrap="wrap"
            className="flex-bottom-cards-home-dashboard"
          >
            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt={t("home.news")} width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>

            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt="News" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>

            <Card
              flex={{ base: "initial", md: "1" }}
              className="card-bottom-home-dashboard"
            >
              <Box className="box-image-home-dashboard">
                <Image src={standardImage} alt="News" width="100%" />
                <Button className="date-button-home-dashboard">
                  Oct. 15 2024
                </Button>
              </Box>
              <Text className="card-bottom-header-text-home-dashboard">
                Update
              </Text>
              <Text className="card-bottom-body-text-home-dashboard">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </Text>
            </Card>
          </Flex>
        </Card>
      </Box>
    </>
  );
}
