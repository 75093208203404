import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Box, useDisclosure, ChakraProvider } from "@chakra-ui/react";
import Header from "./components/Header";
import { useCurrentUserContext } from "./features/user/contexts/user-provider";
import { useCurrentGroupsContext } from "features/groups/contexts/groups-provider";
import groupsApi from "./api/groups";
import SidebarLayout from "./components/SidebarLayout";
import LoadingScreen from "./components/LoadingScreen";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import RecoverPassword from "./pages/RecoverPassword";
import Account from "./pages/Account";
import Participate from "pages/Participate";
import CreateDecision from "./pages/CreateDecision";
import Wallet from "./pages/Wallet";
import HomeDashboard from "./pages/HomeDashboard";
import TableOfCurrentDecision from "./pages/TableOfCurrentDecision";
import ResetPassword from "./pages/ResetPassword";
import MyGroups from "./pages/MyGroups";
import GroupPanelHost from "pages/GroupPanelHost";
import GroupPanelParticipant from "pages/GroupPanelParticipant";
import TranslationHandler from "./pages/TranslationHandling";
import { useCurrentDecisionsContext } from "./features/decisions/contexts/decisions-provider";
import { useCurrentPaymentsContext } from "./features/payments/contexts/payments-provider";
import { theme, darkTheme } from "./theme";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import useMarkdownTranslations from "./translations/i18nextConfig/useMarkdownTranslations";
import HostedDecisions from "./pages/HostedDecisions";

const App = () => {
  const { fetchGroups, fetchGroupsInvitations, fetchDecisions } =
    useCurrentGroupsContext();
  const { authorized, isLoaded, user } = useCurrentUserContext();
  const { getDataOfDecisions, dataOfDecisionsAlreadyCalled } =
    useCurrentDecisionsContext();
  const { fetchPayments } = useCurrentPaymentsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lightMode, setLightMode] = useState(true);
  let isHost = true;
  let isAdmin = true;

  const changeBetweenLightAndDarkMode = () => {
    setLightMode((prevLightMode) => {
      const newLightMode = !prevLightMode;
      localStorage.setItem("lightMode", newLightMode);
      return newLightMode;
    });
  };

  const concatenateDecisions = (actives, invitations) => {
    const decisionsActives = actives.map((active) => ({
      ...active,
      invited: false,
    }));
    const decisionsInvitations = invitations.map((invitation) => ({
      ...invitation,
      invited: true,
    }));

    return decisionsActives.concat(decisionsInvitations);
  };

  const location = useLocation();
  const { t } = useMarkdownTranslations();

  useEffect(() => {
    const baseTitle = "Polity - ";
    {isAdmin && (
      <Route path="/g/:slug/*" element={<GroupPanelHost />} />
    )}
    {!isAdmin && (
      <Route path="/g/:slug" element={<GroupPanelParticipant />} />
    )}
    <Route
      path="/user/groups"
      element={<Navigate to="/user/groups/joined" replace />}
    />

    
    const routeToTitle = {
      "/": authorized ? t("pathname.home") : t("pathname.login"),
      "/participate": t("pathname.participate_decisions"),
      "/hosted-decisions": t("pathname.hosted_decisions"),
      "/switch-group": t("pathname.switch_group"),
      "/account": t("pathname.account"),
      "/signup": t("pathname.sign_up"),
      "/recover-password": t("pathname.recover_password"),
      "/create-decisions": t("pathname.create_decisions"),
      "/wallet": t("pathname.wallet"),
      "/reset-password": t("pathname.reset_password"),
      "/group": t("pathname.group"),
      "/group-home": t("pathname.group_home"),
      "/translation": t("pathname.translation"),
      "/user/groups": t("pathname.my_groups"),
    };

    // Define a regex for dynamic routes
    const dynamicRouteRegexGroups = /^\/user\/groups/;

    const GroupsMatch = location.pathname.match(
      dynamicRouteRegexGroups
    );

    // Define a regex for dynamic routes
    const dynamicRouteRegexCurrentDecision = /^\/current-decision\/(.+)$/;
    const currentDecisionMatch = location.pathname.match(
      dynamicRouteRegexCurrentDecision
    );

    const dynamicRouteRegexGroupHome = /^\/group-home\/(.+)$/;
    const groupHomeMatch = location.pathname.match(dynamicRouteRegexGroupHome);

    const dynamicRouteRegexGroup = /^\/g\/(.+)$/;
    const groupMatch = location.pathname.match(dynamicRouteRegexGroup);

    let currentTitle;

    if (currentDecisionMatch) {
      // If the path matches the dynamic route
      currentTitle = t("pathname.current_decision");
    } else if (groupHomeMatch) {
      currentTitle = t("pathname.group_home");
    } else if (groupMatch) {
      currentTitle = t("pathname.group");
    } else if(GroupsMatch){
      currentTitle = t("pathname.my_groups");
    }
    
    else {
      currentTitle = routeToTitle[location.pathname] || t("pathname.not_found");
    }

    document.title = baseTitle + currentTitle;
  }, [location.pathname, authorized, t]);

  useEffect(() => {
    const getTranslations = async () => {
      const translations = await groupsApi.getTranslations();
    };
    if (authorized) {
      getDataOfDecisions();
      fetchGroups();
      fetchGroupsInvitations();
      fetchDecisions();
      getTranslations();

      if (user && user.id) fetchPayments(user.id);
    }
  }, [authorized]);

  useEffect(() => {
    const savedLightMode = localStorage.getItem("lightMode");
    if (savedLightMode) {
      setLightMode(savedLightMode === "true");
    }
  }, []);

  useEffect(() => {
    const backgroundColor = authorized
      ? "var(--SECONDARY-GREY)"
      : "var(--CLEAR-BACKGROUND)";
    document.body.style.setProperty("background-color", backgroundColor);
  }, [authorized]);

  const handleDataTheme = (isLightMode) => {
    if (isLightMode) {
      document.body.removeAttribute("data-theme");
    } else {
      document.body.setAttribute("data-theme", "dark");
    }
  };

  useEffect(() => {
    handleDataTheme(lightMode);
  }, [lightMode]);


  return (
    <ChakraProvider theme={lightMode ? theme : darkTheme}>
      {authorized && (
        <Box className="app-box-header">
          <Header
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode}
            lightMode={lightMode}
          />
        </Box>
      )}
      <Box className={authorized ? "app-main-box-logged" : "app-main-box"}>
        <Routes>
          {!isLoaded && (
            <Route
              path="*"
              element={<LoadingScreen loading={true} lightMode={lightMode} />}
            />
          )}
          {isLoaded && authorized && (
            <Route
              path="/"
              element={
                <SidebarLayout
                  isOpen={isOpen}
                  onClose={onClose}
                  changeBetweenLightAndDarkMode={changeBetweenLightAndDarkMode}
                  lightMode={lightMode}
                />
              }
            >
              {!dataOfDecisionsAlreadyCalled && (
                <Route path="/" element={<LoadingScreen loading={true} />} />
              )}
              <Route path="/" element={<HomeDashboard />} />
              {!isHost && !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/participate"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {!isHost && (
                <Route
                  path="/participate"
                  element={
                    <Participate
                      lightMode={lightMode}
                      concatenateDecisions={concatenateDecisions}
                    />
                  }
                />
              )}
              {isHost && !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/hosted-decisions"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {isHost && (
                <Route
                  path="/hosted-decisions"
                  element={
                    <HostedDecisions
                      lightMode={lightMode}
                      concatenateDecisions={concatenateDecisions}
                    />
                  }
                />
              )}
              <Route
                path="/account"
                element={<Account lightMode={lightMode} />}
              />
              {user?.["is-staff"] && (
                <Route path="/translation" element={<TranslationHandler />} />
              )}
              {isHost && !dataOfDecisionsAlreadyCalled && (
                <Route
                  path="/create-decisions"
                  element={<LoadingScreen loading={true} />}
                />
              )}
              {isHost && (
                <Route
                  path="/create-decisions"
                  element={<CreateDecision lightMode={lightMode} />}
                />
              )}
              {isAdmin && (
                <Route path="/g/:slug/*" element={<GroupPanelHost />} />
              )}
              {!isAdmin && (
                <Route path="/g/:slug" element={<GroupPanelParticipant />} />
              )}
              <Route path="/user/groups/create" element={<MyGroups />} />
              <Route path="/user/groups/:tab" element={<MyGroups />} />
              <Route
                path="/user/groups"
                element={<Navigate to="/user/groups/joined" replace />}
              />
              <Route path="/wallet" element={<Wallet />} />
              {isHost && (
                <Route
                  path="/current-decision/:id"
                  element={<TableOfCurrentDecision />}
                />
              )}
            </Route>
          )}
          {isLoaded && !authorized && (
            <Route path="/signup" element={<Signup />} />
          )}
          {isLoaded && !authorized && (
            <Route path="/recover-password" element={<RecoverPassword />} />
          )}
          {isLoaded && !authorized && (
            <Route path="/reset-password" element={<ResetPassword />} />
          )}
          {isLoaded && !authorized && <Route path="/" element={<Login />} />}
          {isLoaded && <Route path="*" element={<Navigate to="/" />} />}
        </Routes>
      </Box>
    </ChakraProvider>
  );
};

export default App;
library.add(fab, fas, far);
