import Client from './client';

const api = () => {
    return {
        getGroupsInvitations: () => {
            return Client.get('member/user/invites');
        },
        getAllGroups: () => {
            return Client.get('member/user/group_users');
        },
        getHostedGroups: () => {
            return Client.get('member/user/group_users?role=host');
        },
        getJoinedGroups: () => {
            return Client.get('member/user/group_users?role=member');
        },
        createGroup: (data) => {
            return Client.post('member/user/groups', data);
        },
        getTranslations: () => {
            return Client.get('member/dev/i18n_reference');
        },
        deleteGroup: (groupId) => {
            return Client.delete(`member/groups/${groupId}`);
        },
        getGroupDecisions: (groupId) => {
            return Client.get(`member/groups/${groupId}/decisions`);
        },
        getGroupMembers: (groupId) => {
            return Client.get(`member/groups/${groupId}/members`);
        },
        getGroupMemberData: (groupId, user_id) => {
            return Client.get(`member/groups/${groupId}/group_users?user_id=${user_id}`);
        },    
    };
};

export default api();