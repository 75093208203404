import React, { useState } from "react";
import {
  Card,
  Flex,
  Box,
  Text,
  Button,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import StripePayment from "components/StripePayment";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useCurrentPaymentsContext } from "features/payments/contexts/payments-provider";
import axios from "axios";
import "./styles.css";
import creditCardImage from "assets/images/credit_card.png";
import usdcImage from "assets/images/usdc_logo.png";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function PaymentItems() {
  const [clientSecret, setClientSecret] = useState(null);
  const { user } = useCurrentUserContext();
  const { email, id } = user;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentInformation, setPaymentInformation] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const { subscription } = useCurrentPaymentsContext();
  const subscriptionPrice = process.env.REACT_APP_SUBSCRIPTION_PRICE;
  const sharePrice = process.env.REACT_APP_INVESTMENT_SHARE_PRICE;
  const { t, tMarkdown } = useMarkdownTranslations();

  const handlePaymentIntent = async (
    currentPaymentInformation,
    currentPaymentDescription,
    currentPaymentAmount
  ) => {
    try {
      const paymentEndpoint =
        currentPaymentDescription === "tokens"
          ? "buy-tokens"
          : "buy-subscription";
      const response = await axios.post(
        `${process.env.REACT_APP_STRIPE_BACKEND_URL}/${paymentEndpoint}`,
        {
          paymentAmount: currentPaymentAmount,
          description: currentPaymentDescription,
          userId: id,
          email,
        }
      );

      setClientSecret(response.data.clientSecret);
      setPaymentInformation(currentPaymentInformation);
      setPaymentDescription(currentPaymentDescription);
      setPaymentAmount(currentPaymentAmount);
      onOpen();
    } catch (error) {
      console.error(t("errors.payment.create"), error);
    }
  };

  return (
    <>
      <StripePayment
        isOpen={isOpen}
        onClose={onClose}
        paymentInformation={paymentInformation}
        paymentDescription={paymentDescription}
        paymentAmount={paymentAmount}
        clientSecret={clientSecret}
      />
      <Flex
        direction={{ base: "column", lg: "row" }}
        gap={{ base: 4, md: 8 }}
        py={{ base: 2, md: 4 }}
      >
        {!subscription && (
          <Card flex="1" className="card-form-payment-items">
            <Text className="payment-text-payment-items">
              {t("wallet.membership.token")}
            </Text>
            <Flex>
              <Text className="payment-amount-payment-items">
                ${process.env.REACT_APP_SUBSCRIPTION_PRICE}
              </Text>
              <Text className="single-payment-payment-items">
                {t("wallet.payment.single")}
              </Text>
            </Flex>
            <Box p={2}>
              <Box className="text-circle-payment-items">
                {tMarkdown("wallet.membership.description_html")}
              </Box>
              <Flex direction={{ base: "column", md: "row" }} gap={2}>
                <Button
                  className="payment-button-payment-items"
                  onClick={() =>
                    handlePaymentIntent(
                      t("wallet.membership.token"),
                      "subscription",
                      subscriptionPrice
                    )
                  }
                  leftIcon={<Image src={creditCardImage} />}
                >
                  {t("btn.pay_with_stripe")}
                </Button>
                <Button
                  className="payment-button-payment-items"
                  onClick={() =>
                    handlePaymentIntent(
                      t("wallet.membership.token"),
                      "subscription",
                      subscriptionPrice
                    )
                  }
                  leftIcon={<Image src={usdcImage} />}
                >
                  {t("btn.pay_with_crypto")}
                </Button>
              </Flex>
            </Box>
          </Card>
        )}
        <Card flex="1" className="card-form-payment-items">
          <Text className="payment-text-payment-items">
            {t("wallet.investment.share")}
          </Text>
          <Flex>
            <Text className="payment-amount-payment-items">
              ${process.env.REACT_APP_INVESTMENT_SHARE_PRICE}
            </Text>
            <Text className="single-payment-payment-items">
              {t("wallet.payment.single")}
            </Text>
          </Flex>
          <Box p={2}>
            <Box className="text-circle-payment-items">
              {tMarkdown("wallet.investment.description_html")}
            </Box>
            <Flex direction={{ base: "column", md: "row" }} gap={2}>
              <Button
                className="payment-button-payment-items"
                onClick={() =>
                  handlePaymentIntent(
                    t("wallet.investment.share"),
                    "tokens",
                    sharePrice
                  )
                }
                leftIcon={<Image src={creditCardImage} />}
              >
                {t("btn.pay_with_stripe")}
              </Button>
              <Button
                className="payment-button-payment-items"
                onClick={() =>
                  handlePaymentIntent(
                    t("wallet.investment.share"),
                    "tokens",
                    sharePrice
                  )
                }
                leftIcon={<Image src={usdcImage} />}
              >
                {t("btn.pay_with_crypto")}
              </Button>
            </Flex>
          </Box>
        </Card>
      </Flex>
    </>
  );
}
