import React, { useState } from "react";
import {
  Card,
  Box,
  Button,
  Text,
  Flex,
  Link,
  useDisclosure,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { timeDifference, formatDate } from "utils";
import standardImage from "assets/images/standard_image.png";
import ModalGroupsCardDecision from "components/ModalGroupsCardDecision"; // Import the modal component

const GroupsCardDecision = ({ currentDecision, isAdmin }) => {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Manage modal state

  // Destructure attributes and links for cleaner code
  const { attributes = {}, links = {} } = currentDecision;

  return (
    <>
      {/* Render the modal and pass the necessary props */}
      <ModalGroupsCardDecision
        isOpen={isOpen}
        onClose={onClose}
        currentDecision={currentDecision}
        isAdmin={isAdmin}
      />
      <Card className="card-decision-card-host">
        <Box className="box-image-decision-card-host">
          <Image
            className="image-decision-card-host"
            src={
              isError ? standardImage : attributes["image-url"] || standardImage
            }
            alt={t("decision")}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-host">{attributes["title"]}</Text>
        <Link
          href={
            attributes["current-mode"] === "Unpublished"
              ? links?.preview
              : links?.visit
          }
          target="_blank"
        >
          {attributes["current-mode"] === "Unpublished"
            ? links?.preview
            : links?.visit}
        </Link>
        <Flex direction="row" gap={2}>
          {attributes["current-mode"] === "open" && (
            <Link href={links?.visit}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                70% completed
              </Button>
            </Link>
          )}

          {attributes["current-mode"]?.toLowerCase().includes("finished") && (
            <Link href={links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-check"
                  className="icon-button-decision-card-host"
                />
                {t("btn.view_results")}
              </Button>
            </Link>
          )}

          {attributes["current-mode"]
            ?.toLowerCase()
            .includes("unpublished") && (
            <Link href={links?.results}>
              <Button className="special-link-button-decision-card-host">
                <FontAwesomeIcon
                  icon="fa-regular fa-moon"
                  className="icon-button-decision-card-host"
                />
                {t("btn.unpublished")}
              </Button>
            </Link>
          )}
        </Flex>
        <Flex direction="column">
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t("decision.details.opens_on")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {attributes["open-at"] ? (
                <>
                  {formatDate(attributes["open-at"])} (
                  {timeDifference(attributes["open-at"])} )
                </>
              ) : (
                <>{t("decision.details.to_be_determined")}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t("decision.details.closes")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {attributes["close-at"] ? (
                <>
                  {formatDate(attributes["close-at"])} (
                  {timeDifference(attributes["close-at"])} )
                </>
              ) : (
                <>{t("decision.details.to_be_determined")}</>
              )}
            </Text>
          </Flex>
        </Flex>

        {isAdmin && <Flex
          className="flex-buttons-decision-card-host"
          gap={2}
          direction="row"
        >
          {links?.visit && (
            <Tooltip label={t("tooltip.visit")}>
              <Link href={links?.visit} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-eye"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {links?.edit && (
            <Tooltip label={t("tooltip.edit")}>
              <Link href={links?.edit} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {links?.analysis && (
            <Tooltip label={t("tooltip.analysis")}>
              <Link href={links?.analysis} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-chart-simple"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}

          <Tooltip label={t("tooltip.invite")}>
            <Button
              onClick={() => onOpen()}
              className="icon-bottom-button-decision-card-host"
              width="100%"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-user-plus"
                className="icon-decision-card-host"
              />
            </Button>
          </Tooltip>

          {links?.comments && (
            <Tooltip label={t("tooltip.comments")}>
              <Link href={links?.comments} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-comment"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
        </Flex>}

        {/* Update the button to open the modal instead of navigating */}
        <Button
          className="button-details-decision-card-host"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={onOpen} // Open the modal when clicked
        >
          {t("btn.show_details")}
        </Button>
      </Card>
    </>
  );
};

export default GroupsCardDecision;

/*import React, { useState } from "react";
import {
  Card,
  Box,
  Button,
  Text,
  Flex,
  Link,
  useDisclosure,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { timeDifference, formatDate } from "utils";
import standardImage from "assets/images/standard_image.png";

const GroupsCardDecision = ({ currentDecision }) => {
  const { t } = useMarkdownTranslations();
  const [isError, setIsError] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  // Destructure attributes and links for cleaner code
  const { attributes = {}, links = {} } = currentDecision;

  return (
    <>
      <Card className="card-decision-card-host">
        <Box className="box-image-decision-card-host">
          <Image
            className="image-decision-card-host"
            src={
              isError
                ? standardImage
                : attributes["image-url"] || standardImage
            }
            alt={t("decision")}
            onError={() => setIsError(true)}
          />
        </Box>
        <Text className="title-decision-card-host">
          {attributes["title"]}
        </Text>
        <Link
          href={
            attributes["current-mode"] === "Unpublished"
              ? links?.preview
              : links?.visit
          }
          target="_blank"
        >
          {attributes["current-mode"] === "Unpublished"
            ? links?.preview
            : links?.visit}
        </Link>

        {attributes["current-mode"] === "open" && (
          <Link href={links?.visit}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-moon"
                className="icon-button-decision-card-host"
              />
              70% completed
            </Button>
          </Link>
        )}

        {attributes["current-mode"]?.toLowerCase().includes("finished") && (
          <Link href={links?.results}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-check"
                className="icon-button-decision-card-host"
              />
              {t("btn.view_results")}
            </Button>
          </Link>
        )}

        {attributes["current-mode"]?.toLowerCase().includes("unpublished") && (
          <Link href={links?.results}>
            <Button className="special-link-button-decision-card-host">
              <FontAwesomeIcon
                icon="fa-regular fa-moon"
                className="icon-button-decision-card-host"
              />
              {t("btn.unpublished")}
            </Button>
          </Link>
        )}
        <Flex direction="column">
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t("decision.details.opens_on")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {attributes["open-at"] ? (
                <>
                  {formatDate(attributes["open-at"])} (
                  {timeDifference(attributes["open-at"])} )
                </>
              ) : (
                <>{t("decision.details.to_be_determined")}</>
              )}
            </Text>
          </Flex>
          <Flex className="date-flex-decision-card-host">
            <Text className="date-tag-decision-card-host">
              {t("decision.details.closes")}:
            </Text>
            <Text ml={0} className="date-text-decision-card-host">
              {attributes["close-at"] ? (
                <>
                  {formatDate(attributes["close-at"])} (
                  {timeDifference(attributes["close-at"])} )
                </>
              ) : (
                <>{t("decision.details.to_be_determined")}</>
              )}
            </Text>
          </Flex>
        </Flex>

        <Flex
          className="flex-buttons-decision-card-host"
          gap={2}
          direction="row"
        >
          {links?.visit && (
            <Tooltip label={t("tooltip.visit")}>
              <Link href={links?.visit} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-eye"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {links?.edit && (
            <Tooltip label={t("tooltip.edit")}>
              <Link href={links?.edit} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-pen"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
          {links?.analysis && (
            <Tooltip label={t("tooltip.analysis")}>
              <Link href={links?.analysis} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-chart-simple"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}

          <Tooltip label={t("tooltip.invite")}>
            <Button
              onClick={() => onOpen()}
              className="icon-bottom-button-decision-card-host"
              width="100%"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-user-plus"
                className="icon-decision-card-host"
              />
            </Button>
          </Tooltip>

          {links?.comments && (
            <Tooltip label={t("tooltip.comments")}>
              <Link href={links?.comments} target="_blank" width="100%">
                <Button className="icon-bottom-button-decision-card-host">
                  <FontAwesomeIcon
                    icon="fa-solid fa-comment"
                    className="icon-decision-card-host"
                  />
                </Button>
              </Link>
            </Tooltip>
          )}
        </Flex>

        <Button
          className="button-details-decision-card-host"
          rightIcon={<FontAwesomeIcon icon="fa-solid fa-chevron-down" />}
          justifyContent="space-between"
          onClick={() =>
            navigate(`/current-decision/${attributes["decision-id"]}`)
          }
        >
          {t("btn.show_details")}
        </Button>
      </Card>
    </>
  );
};

export default GroupsCardDecision;*/
