import { useEffect, useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Link,
  Box,
  Image,
  FormControl,
  Center,
  Text,
  Grid,
  InputLeftElement
} from "@chakra-ui/react";
import userApi from 'api/auth';
import isMetaMaskInstalled from 'features/web3/utils/isMetaMaskInstalled';
import connectWallet from 'features/web3/utils/connectMetamask';
import personalSign from 'features/web3/utils/personalSign';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { getErrorMessage } from "utils";
import MetamaskLogo from 'assets/images/metamask_logo.svg';
import politySideImage from 'assets/images/polity_side.png';
import emailIcon from 'assets/images/email_icon.png';
import passwordIcon from 'assets/images/password_icon.png';
import "./styles.css";

const LoginWithoutMetamaskInstalled = () => {
  const { t } = useMarkdownTranslations();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberUser, setRememberUser] = useState(true);
  const navigate = useNavigate();
  const { login } = useCurrentUserContext();
  const hasMetamaskExtension = isMetaMaskInstalled();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [web3Error, setWeb3Error] = useState(false);
  const [web3TextError, setWeb3TextError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const loginToApp = (email, password) => {
    const data = { email, password, remember_me: rememberUser };

    userApi
      .login({ user: data })
      .then(() => {
        login();
        navigate('/', { replace: true });
      })
      .catch((e) => {
        setError(true);
        e?.data?.errors?.forEach(currentError => {
          setErrorMessage(getErrorMessage(currentError.detail));
        });
      });
  };

  const connectWithMetamask = async (nonce, publicAddress) => {
    const message = `Ethelo Login,${Date.now()},${nonce}`;
    const { signature } = await personalSign({ message, publicAddress });
    if (signature) {
      const user = {
        public_address: publicAddress,
        message,
        signature,
      };
      userApi['loginWithMetamask']({ user })
        .then(() => {
          login();
          navigate('/', { replace: true });
        })
        .catch(() => {

        });
    }
  };

  const loginInAppWithWeb3 = async () => {
    const { publicAddress } = await connectWallet();
    if (publicAddress) {
      userApi.nonce({ user: { public_address: publicAddress } })
        .then(response => {
          connectWithMetamask(response.nonce, publicAddress);
        })
        .catch((e) => {
          e?.data?.errors?.forEach(currentError => {
            if (currentError.source.pointer === "/data/attributes/auth") {
              setWeb3Error(true);
              setWeb3TextError(getErrorMessage(currentError.detail));
            }
          });
        });
    }
  };

  useEffect(() => {
    setError(false);
  }, [email, password]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Flex className="main-flex-without-metamask" height="100vh">
      <Grid className="grid-without-metamask">
        {windowWidth > 768 && <Image src={politySideImage} className="side-image-without-metamask" minHeight="100vh" maxHeight="100vh" />}
        <Stack className="main-stack-without-metamask" minHeight="100vh" maxHeight="100vh">
          <Box className="register-text-without-metamask">{t("authentication.login")}</Box>
          <Box className="welcome-text-without-metamask">{t("company.welcome")}</Box>
          <Box className="main-box-without-metamask">
            <Stack className="secondary-stack-without-metamask" role="form" aria-label={t("accessibility.authentication.login_form")}>
              <FormControl className="first-form-control-without-metamask">
                <Center>
                  <InputGroup>
                    <InputLeftElement className="input-left-element-without-metamask" children={<Image src={emailIcon} alt={t("participant.email")} />} />
                    <Input placeholder={t("participant.email")} type="email" aria-labelledby={t("participant.email")} value={email} onChange={e => setEmail(e.target.value)} className={"input-without-metamask"} />
                  </InputGroup>
                </Center>
              </FormControl>
              <FormControl>
                <Center>
                  <Flex className="flex-column-without-metamask" direction="column" w="100%">
                    <InputGroup>
                      <InputLeftElement className="input-left-element-without-metamask" children={<Image src={passwordIcon} alt={t("authentication.password")} />} />
                      <Input placeholder={t("authentication.password")} value={password} aria-labelledby="password" onChange={e => setPassword(e.target.value)} type="password" className={"input-without-metamask"} />
                    </InputGroup>
                    {error && <Text className="error-text-without-metamask">{errorMessage}</Text>}
                  </Flex>
                </Center>
              </FormControl>
              <Box className="button-box-without">
                {hasMetamaskExtension && <Flex direction="column">
                  <Button className="metamask-button-without-metamask" onClick={() => loginInAppWithWeb3()}>
                    <Image className="metamask-logo-without-metamask" src={MetamaskLogo} /> {t("authentication.web3.connect")}
                  </Button>
                  {web3Error && <Text className="errorText-signup" aria-label={web3TextError}>{web3TextError}</Text>}
                </Flex>}
                <Center>
                  <Button className="login-button-without-metamask" onClick={() => loginToApp(email, password)}>
                    {t("authentication.login")}
                  </Button>
                </Center>
              </Box>
              <Flex className="bottom-without-metamask">
                <Flex direction="column">
                  <Box className="create-account-text-without-metamask">{t("authentication.question.want_create_account")}</Box>
                  <Box className="create-account-text-without-metamask">{t("authentication.question.forgot_password")}</Box>
                </Flex>
                <Flex direction="column">
                  <Link as={RouterLink} className="create-account-link-without-metamask" to="/signup">{t("authentication.account.create")}</Link>
                  <Link as={RouterLink} className="create-account-link-without-metamask" to="/recover-password">{t("authentication.password.reset")}</Link>
                </Flex>
              </Flex>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Flex>
  );
};

export default LoginWithoutMetamaskInstalled;