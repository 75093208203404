import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Flex,
  Tooltip,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import ModalDeleteMember from "components/GroupAsAdmin/ModalDeleteMember";
import ModalEditMember from "components/ModalEditMember";

const dummyData = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    web3Address: "0x1234...5678",
    role: "Admin",
    decisions: 15,
    joinedOn: "2024-01-15",
    lastVisit: "2024-03-20"
  },
  {
    id: 2,
    name: "Alice Smith",
    email: "alice.smith@example.com",
    web3Address: "0x8765...4321",
    role: "Member",
    decisions: 8,
    joinedOn: "2024-02-01",
    lastVisit: "2024-03-19"
  },
  {
    id: 3,
    name: "Bob Wilson",
    email: "bob.wilson@example.com",
    web3Address: "0x9876...1234",
    role: "Member",
    decisions: 12,
    joinedOn: "2024-01-20",
    lastVisit: "2024-03-18"
  },
  {
    id: 4,
    name: "Emma Johnson",
    email: "emma.j@example.com",
    web3Address: "0xABCD...EF12",
    role: "Admin",
    decisions: 25,
    joinedOn: "2023-12-10",
    lastVisit: "2024-03-21"
  },
  {
    id: 5,
    name: "Michael Brown",
    email: "michael.b@example.com",
    web3Address: "0x2468...13579",
    role: "Member",
    decisions: 5,
    joinedOn: "2024-02-15",
    lastVisit: "2024-03-15"
  },
  {
    id: 6,
    name: "Sarah Davis",
    email: "sarah.d@example.com",
    web3Address: "0x1357...24680",
    role: "Member",
    decisions: 18,
    joinedOn: "2024-01-05",
    lastVisit: "2024-03-17"
  },
  {
    id: 7,
    name: "David Miller",
    email: "david.m@example.com",
    web3Address: "0xFEDC...BA98",
    role: "Admin",
    decisions: 3,
    joinedOn: "2024-03-01",
    lastVisit: "2024-03-21"
  },
  {
    id: 8,
    name: "Lisa Anderson",
    email: "lisa.a@example.com",
    web3Address: "0x3456...7890",
    role: "Admin",
    decisions: 30,
    joinedOn: "2023-11-20",
    lastVisit: "2024-03-20"
  },
  {
    id: 9,
    name: "James Wilson",
    email: "james.w@example.com",
    web3Address: "0x7890...1234",
    role: "Member",
    decisions: 7,
    joinedOn: "2024-02-10",
    lastVisit: "2024-03-16"
  },
  {
    id: 10,
    name: "Patricia Moore",
    email: "patricia.m@example.com",
    web3Address: "0x4567...8901",
    role: "Member",
    decisions: 22,
    joinedOn: "2023-12-15",
    lastVisit: "2024-03-21"
  }
];

const TableOfGroupMember = ({search, sortBy}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredData, setFilteredData] = useState(dummyData);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 5;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleDelete = (userId) => {
  };

  useEffect(() => {
    const filtered = dummyData.filter((row) => {
      const searchLower = search.toLowerCase();
      return (
        row.name.toLowerCase().includes(searchLower) ||
        row.email.toLowerCase().includes(searchLower) ||
        row.web3Address.toLowerCase().includes(searchLower)
      );
    });
    if (sortBy && sortBy !== "none") {
      filtered.sort((a, b) => {
        switch (sortBy) {
          case "name":
            return a.name.localeCompare(b.name);
          case "email":
            return a.email.localeCompare(b.email);
          case "web3Address":
            return a.web3Address.localeCompare(b.web3Address);
          case "role":
            return a.role.localeCompare(b.role);
          case "lastInviteSent":
            return new Date(b.lastInviteSent) - new Date(a.lastInviteSent);
          default:
            return 0;
        }
      });
    }
    setFilteredData(filtered);
    setPageIndex(0); // Reset page when filtering
  }, [search, sortBy]);

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (filteredData.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = () => {
    const pagesNumber = Math.ceil(filteredData.length / pageSize);
    const buttons = [];
    for (let i = 0; i < pagesNumber; i++) {
      buttons.push(
        <Button
          key={i}
          className="button-change-page-decision-copy-list"
          color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
          bgColor={
            pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
          }
          onClick={() => setPageIndex(i)}
        >
          {i + 1}
        </Button>
      );
    }
    return buttons;
  };

  const truncateString = (str) => {
    return str.length > 9 ? `${str.substring(0, 9)}...` : str;
  };

  const currentPageData = filteredData.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize
  );

  return (
    <Box className="main-box-table-of-current-decision" style={{ padding: "0" }}>
      <Box className="secondary-box-table-of-current-decision" style={{ padding: "0" }}>
        <Box
          background="var(--CLEAR-BACKGROUND)"
          borderRadius="18px"
          p={{ base: "16px", md: "24px" }}
        >

          <Box borderRadius="12px" boxShadow="md">
            <Table
              variant="simple"
              size="md"
              borderWidth="1px"
              borderColor="gray.100"
              width="100%"
              borderRadius="12px"
            >
              <Thead className="thead-table-of-current-decision">
                <Tr>
                  <Th className="th-table-of-current-decision">Name</Th>
                  <Th className="th-table-of-current-decision">Email</Th>
                  <Th className="th-table-of-current-decision">Web3 Address</Th>
                  <Th className="th-table-of-current-decision">Role</Th>
                  <Th className="th-table-of-current-decision">Decisions</Th>
                  <Th className="th-table-of-current-decision">Joined On</Th>
                  <Th className="th-table-of-current-decision">Last Visit</Th>
                  <Th className="th-table-of-current-decision"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentPageData.map((row) => (
                  <Tr key={row.id}>
                    <Td className="td-table-of-current-decision">{row.name}</Td>
                    <Td className="td-table-of-current-decision">
                      <Tooltip label={row.email} placement="top">
                        <Flex align="center" justify="space-between">
                          {truncateString(row.email)}
                          <IconButton
                            aria-label="Copy email"
                            icon={<CopyIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => navigator.clipboard.writeText(row.email)}
                          />
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td className="td-table-of-current-decision">
                      <Tooltip label={row.web3Address} placement="top">
                        <Flex align="center" justify="space-between">
                          {truncateString(row.web3Address)}
                          <IconButton
                            aria-label="Copy web3 address"
                            icon={<CopyIcon />}
                            size="sm"
                            variant="ghost"
                            onClick={() => navigator.clipboard.writeText(row.web3Address)}
                          />
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td className="td-table-of-current-decision">{row.role}</Td>
                    <Td className="td-table-of-current-decision">{row.decisions}</Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                      {row.joinedOn}
                    </Td>
                    <Td className="td-table-of-current-decision td-date-table-of-current-decision">
                      {row.lastVisit}
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                        >
                          Password Reset
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-eye" />
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                          onClick={() => handleEditClick(row)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pencil-alt" />
                        </Button>
                        <Button
                          size="sm"
                          className="action-button-table-of-current-decision"
                          onClick={() => handleDeleteClick(row)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </Button>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box w={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              <Flex w={"250px"} justify="center" mt={4}>
                <Text fontSize="sm" color="gray.600">
                  Showing {pageIndex * pageSize + 1} to{" "}
                  {Math.min((pageIndex + 1) * pageSize, filteredData.length)} of{" "}
                  {filteredData.length} members
                </Text>
              </Flex>
            </Box>
            <Box>
              <Flex justify="center" mt={6} gap={2}>
                <Button
                  onClick={previousPage}
                  isDisabled={pageIndex === 0}
                  className="button-change-page-decision-copy-list"
                  bgColor={"var(--CLEAR-BACKGROUND)"}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                </Button>
                <Flex gap={2}>{getPaginationButtons()}</Flex>
                <Button
                  onClick={nextPage}
                  isDisabled={filteredData.length <= (pageIndex + 1) * pageSize}
                  className="button-change-page-decision-copy-list"
                  bgColor={"var(--CLEAR-BACKGROUND)"}
                >
                  <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                </Button>
              </Flex>
            </Box>
            <Box w={"250px"}></Box>
          </Box>
        </Box>
      </Box>
      {selectedUser && (
        <ModalDeleteMember
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          user={selectedUser}
          onDelete={handleDelete}
        />
      )}
      <ModalEditMember isOpen={isOpen} onClose={onClose} user={selectedUser} />
    </Box>
  );
};

export default TableOfGroupMember;
