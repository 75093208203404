import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import logo from "../../../assets/images/polity_dark_mode2.png";

const ModalDeleteMember = ({ isOpen, onClose, user, onDelete }) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete(user.id);
    }
    onClose();
  };

  return (
    <Modal padding={'0px'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="18px" p={4}>
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          gap={2} 
          borderBottom="1px" 
          borderColor="gray.300"
        >
          <img src={logo} alt="logo" width={126} height={23} />
          <Text fontSize="lg" fontWeight="bold" mb={0}>Are you sure you want to delete this participant?</Text>
          <Text fontSize="sm" color="gray.600">Click below to  decide</Text>
        </Box>
        
        <ModalBody>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              Name
            </Text>
            <Text mt={2} fontSize="sm">
              {user.name}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              Email
            </Text>
            <Text mt={2} fontSize="sm">
              {user.email}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              Wallet
            </Text>
            <Text mt={2} fontSize="sm">
              {user.web3Address}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Text mt={2} fontWeight="bold" fontSize="sm">
              Role
            </Text>
            <Text mt={2} fontSize="sm">
              {user.role}
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button 
            onClick={onClose}
            width="70%"
            border="1px solid var(--MAIN-GREY)"
            backgroundColor="var(--CLEAR-BACKGROUND)"
            className="secondary-button"
          >
            Go Back
          </Button>
          <Button
            className="danger-button"
            onClick={handleDelete}
            width="30%"
          >
            I'm sure
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalDeleteMember;
