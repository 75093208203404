import { useReducer } from "react";
import { groupsInitState, groupsReducer } from "features/groups/reducer";
import apiGroups from "api/groups";
import { GROUPS } from "features/groups/actions";

export default function useCurrentGroups() {
  const [state, dispatch] = useReducer(groupsReducer, groupsInitState);

  const fetchGroups = async () => {
    try {
      const responseHosted = await apiGroups.getHostedGroups();
      const responseJoined = await apiGroups.getJoinedGroups();
      const responseAllGroups = await apiGroups.getAllGroups();

      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: responseHosted?.included ?? responseHosted?.data,
      });

      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: responseJoined?.included ?? responseJoined?.data,
      });

      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: responseAllGroups?.included ?? responseAllGroups?.data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: [],
      });

      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: [],
      });

      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: [],
      });
    }
  };

  const fetchGroupsInvitations = async () => {
    try {
      const response = await apiGroups.getGroupsInvitations();

      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload: response?.included ?? response?.data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload:  [],
        
      });
    }
  };

  /*Decisions of group 1 fetched by development reasons */
  const fetchDecisions = async (groupId) => {
    try {
      const { data } = await apiGroups.getGroupDecisions(1);
      dispatch({
        type: GROUPS.GROUPS_DECISIONS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_DECISIONS,
        payload: {
          decisions: [],
        },
      });
    }
  }

  return {
    ...state,
    fetchGroups,
    fetchGroupsInvitations,
    fetchDecisions
  };
}
