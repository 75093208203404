import React from 'react';
import useCurrentDecisions from 'features/decisions/hooks/useCurrentDecisions';

const DecisionsContext = React.createContext();

const DecisionsProvider = ({children}) => {
  const currentUTableState = useCurrentDecisions();
  
  return (
    <DecisionsContext.Provider value={currentUTableState}>
      {children}
    </DecisionsContext.Provider>
  );
};

const useCurrentDecisionsContext = () => {
  const context = React.useContext(DecisionsContext);
  if (context === undefined) {
    throw new Error('currentDecisionsState must be used within a decisionsProvider');
  }
  return context;
};

export { DecisionsProvider, useCurrentDecisionsContext }
