import React from "react";
import {
  Box,
  Image,
  Text,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import HTMLReactParser from "html-react-parser";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";
import ModalCreateDecision from "../ModalCreateDecision";
import standardImage from "../../assets/images/standard_image.png";

export default function TemplateCard({ decisionTemplate }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, image, info, links } = decisionTemplate;
  const { t } = useMarkdownTranslations();

  const handleVisitClick = () => {
    const url = links.visit ? links.visit : links.self ? links.self : "";
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <ModalCreateDecision isOpen={isOpen} onClose={onClose} decisionData={decisionTemplate}/>
      <Box
        className="main-box-template-card"
        role="region"
        aria-label={t("accessibility.card.template")}
      >
        <Image
          src={image || standardImage}
          alt={t("decision.template")}
          className="image-template-card"
        />
        <Text
          className="text-title-template-card"
          role="heading"
          aria-level="1"
        >
          {title}
        </Text>
        <Text className="html-text-template-card" role="heading" aria-level="2">
          {HTMLReactParser(info)}
        </Text>
        <Flex className="flex-template-card" gap={8}>
          <Button
            onClick={() => onOpen()}
            className="first-button-template-card"
            flex="1"
          >
            {t('btn.create')}
          </Button>
          <Button
            onClick={() => handleVisitClick()}
            className="second-button-template-card"
            flex="1"
          >
            {t('btn.demo')}
          </Button>
        </Flex>
      </Box>
    </>
  );
}